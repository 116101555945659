import React, { useState, useEffect } from 'react';
import '../../styles/Dashboard.css';
import BudgetTable from './BudgetTable';
//Firebase Imports
import { ref, get, set } from "firebase/database";
import { database } from '../../firebaseConfig';

const ReviewModule = ({ selectedDistrictInfo }) => {
    const districtCode = selectedDistrictInfo['DISTRICT_COUNTY_CODE'];
    const [data, setData] = useState(null);
    const [historicalData, setHistoricalData] = useState({});
    const [viewMode, setViewMode] = useState('value'); // "VALUE" or "YTD"

    const [currentYear, setCurrentYear] = useState(null);
    const [priorYear, setPriorYear] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);

    // Effect 1: Determine available years and months
    useEffect(() => {
        const determineYearsAndMonths = async () => {
            try {
                const districtRef = ref(database, `budget/${districtCode}`);
                const districtSnapshot = await get(districtRef);
    
                if (districtSnapshot.exists()) {
                    const budgetData = districtSnapshot.val();
                    setHistoricalData(budgetData);

                    // Get all available years and sort them in descending order
                    const availableYears = Object.keys(budgetData)
                        .map(year => parseInt(year))
                        .filter(year => !isNaN(year))
                        .sort((a, b) => b - a); // Sort descending
    
                    if (availableYears.length === 0) return;
    
                    let latestYear = null;
                    let latestMonth = null;
                    const monthOrder = ["July", "August", "September", "October", "November", "December", 
                                        "January", "February", "March", "April", "May", "June"];
    
                    for (const year of availableYears) {
                        const expenseData = budgetData[year]?.expense || {};
                        let monthsForYear = [];
    
                        // Limit iteration to the first 100 account codes
                        Object.keys(expenseData)
                            .slice(0, 100)
                            .forEach(accountCode => {
                                const observedMonths = Object.keys(expenseData[accountCode]?.observed || {});
                                monthsForYear.push(...observedMonths);
                            });
    
                        // Deduplicate and sort months based on monthOrder
                        monthsForYear = [...new Set(monthsForYear)]
                            .sort((a, b) => monthOrder.indexOf(b) - monthOrder.indexOf(a));
    
                        if (monthsForYear.length > 0) {
                            latestYear = year;
                            latestMonth = monthsForYear[0]; // The most recent month found
                            break; // Stop at the first year with valid months
                        }
                    }
    
                    if (latestYear && latestMonth) {
                        setCurrentYear(latestYear);
                        setPriorYear(availableYears.find(y => y < latestYear) || latestYear - 1);
                        setCurrentMonth(latestMonth);
                    }
                }
            } catch (error) {
                console.error("Error fetching available years and months:", error);
            }
        };
    
        determineYearsAndMonths();
    }, [districtCode]); // Runs only when districtCode changes
    

// Effect 2: Fetch data based on selected year and month
useEffect(() => {
    if (!currentYear || !currentMonth) return;

    const fetchData = async () => {
        try {
            const currentYearRef = ref(database, `budget/${districtCode}/${currentYear}`);
            const priorYearRef = ref(database, `budget/${districtCode}/${priorYear}`);

            const [currentYearSnapshot, priorYearSnapshot] = await Promise.all([
                get(currentYearRef),
                get(priorYearRef)
            ]);

            if (currentYearSnapshot.exists()) {
                const rawData = {
                    current: currentYearSnapshot.val(),
                    prior: priorYearSnapshot.exists() ? priorYearSnapshot.val() : null
                };
                setData(processData(rawData, viewMode));
            } else {
                console.warn("No data available for the current year");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
}, [districtCode, viewMode, currentYear, currentMonth]);

    
    const processData = (rawData, mode) => { // Add mode as a parameter
        const result = {
            revenue: {},
            expense: {},
            cash: { '10': 0, '20': 0, '30': 0, '40': 0, '60': 0 },
            priorYear: {
                revenue: {},
                expense: {},
                cash: {}
            }
        };
    
        // Process current year data
        Object.keys(rawData.current).forEach(group => {
            Object.keys(rawData.current[group] || {}).forEach(accountCode => {
                const currentMonthData = rawData.current[group][accountCode]['observed'][currentMonth];

                if (!currentMonthData) return;
    
                const value = mode === 'value' ? currentMonthData.value || 0 : currentMonthData.ytd || 0; // Use mode
                const fund = String(currentMonthData.fund);

                if (group === 'revenue') {
                    result.revenue[fund] = (result.revenue[fund] || 0) + value;
                } else if (group === 'expense') {
                    result.expense[fund] = (result.expense[fund] || 0) + value;
                } else if (group === 'cash') {
                    const value = currentMonthData.value || 0; // Always use the latest value
                
                    if (fund === '30') {
                        result.cash['30'] = value; // Keep Fund 30 separate
                    } else {
                        result.cash['Operating'] = (result.cash['Operating'] || 0) + value; // Lump all other funds into "Operating"
                    }
                }
            });
        });
    
        // Process prior year data if available
        if (rawData.prior) {
            Object.keys(rawData.prior).forEach(group => {
                Object.keys(rawData.prior[group] || {}).forEach(accountCode => {
                    const priorYearData = rawData.prior[group][accountCode]['observed'][currentMonth];

                    if (!priorYearData) return;
    
                    //const priorValue = priorYearData.value || 0;
                    const priorValue = mode === 'value' ? priorYearData.value || 0 : priorYearData.ytd || 0; // Use mode
                    const fund = String(priorYearData.fund);
    
                    if (group === 'revenue') {
                        result.priorYear.revenue[fund] = (result.priorYear.revenue[fund] || 0) + priorValue;
                    } else if (group === 'expense') {
                        result.priorYear.expense[fund] = (result.priorYear.expense[fund] || 0) + priorValue;
                    } else if (group === 'cash') {
                        const priorValue = priorYearData.value || 0;
                    
                        if (fund === '30') {
                            result.priorYear.cash['30'] = priorValue; // Keep Fund 30 separate
                        } else {
                            result.priorYear.cash['Operating'] = (result.priorYear.cash['Operating'] || 0) + priorValue; // Aggregate others
                        }
                    }
                });
            });
        }
    
        return result;
    };    

    const renderYearMonthSelector = () => {
        const availableYears = Array.from({ length: (priorYear ? currentYear - priorYear + 1 : 1) }, (_, i) => (priorYear || currentYear) + i);
        const monthOrder = ["July", "August", "September", "October", "November", "December", 
                            "January", "February", "March", "April", "May", "June"];
    
        return (
            <div className="year-month-selector" style={{ display: "flex", justifyContent: "center", gap: "20px", marginBottom: "10px" }}>
                <div>
                    <select value={currentMonth || ''} onChange={(e) => setCurrentMonth(e.target.value)}>
                        {monthOrder.map(month => (
                            <option key={month} value={month}>{month}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <select value={currentYear || ''} onChange={(e) => setCurrentYear(parseInt(e.target.value))}>
                        {availableYears.map(year => (
                            <option key={year} value={year}>{year}</option>
                        ))}
                    </select>
                </div>
            </div>
        );
    };    

    const getFundLabel = (fund) => {
        const fundLabels = {
            '10': 'General',
            '20': 'Teacher',
            '30': 'Debt',
            '40': 'Capital'
        };
        return fundLabels[fund] || fund;
    };


    return (
        <div className="dashboard">
            {renderYearMonthSelector()}
            <div className="title" style={{ fontSize: '40px' }}>Review</div>
            <div className="drilldownTableContainer">
            <BudgetTable
              districtCode={districtCode}
              year={currentYear}
              month={currentMonth}
              historicalData={historicalData}
              showProgramFilter={true}
              showProjectFilter={true}
              showSourceFilter={true}
              currentMonth={currentMonth}
              currentYear={currentYear}
            />
          </div>
        </div>
    );
};

export default ReviewModule;
