import React, {useState} from 'react';
import '../styles/Header.css';
import logo from '../logo.png';
import gear from '../gear.png'
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const Header = ({ selectedDistrict, 
                  selectedPeerGroup, 
                  peerGroupOptions,
                  boardView, 
                  superAdmin,
                  uniqueDistrictNames,
                  handleDistrictChange, 
                  handlePeerGroupChange,
                  handleBoardViewChange
                }) => {

    const auth = getAuth();
    const navigate = useNavigate();
    const [settingsDropdown, setSettingsDropdown] = useState(false);

    const handleLogout = () => {
        signOut(auth)
            .then(() => {
            console.log('User signed out successfully');
            navigate('/login');
            })
            .catch((error) => {
            console.error('Error signing out:', error);
            });
    };


    const toggleSettingsDropdown = () => {
        setSettingsDropdown(!settingsDropdown);
    };    
            
    return (
        <header className="app-header">
            <img src={logo} alt="Company Logo" className="logo" /> {/* Logo image */}
            <div className="school-selection">
                {/* Conditionally render dropdowns based on boardView */}
                {!boardView && (
                    <>
                        {/* Dropdown to select district (only available for superAdmins)*/}
                        {superAdmin && uniqueDistrictNames.length > 0 && (
                            <>
                                <label htmlFor="school-select">District:</label>
                                <select id="school-select" value={selectedDistrict} onChange={handleDistrictChange}>
                                    <option value="" disabled>Select a District</option>
                                    {uniqueDistrictNames.map((district, index) => (
                                        <option key={index} value={district}>{district}</option>
                                    ))}
                                </select>
                            </>
                        )}

                        {/* Dropdown to select peer group */}
                        {peerGroupOptions.length > 0 && (
                            <>
                                <label htmlFor="peers-select">Peer Group:</label>
                                <select id="peers-select" value={selectedPeerGroup} onChange={handlePeerGroupChange}>
                                    <option value="" disabled>Select a Peer Group</option>
                                    {peerGroupOptions.map((peer, index) => (
                                        <option key={index} value={peer}>{peer}</option>
                                    ))}
                                </select>
                            </>
                        )}

                        {/* Dropdown to select Board/Admin view */}
                        <label htmlFor="board-view-select">View:</label>
                        <select id="board-view-select" value={boardView} onChange={handleBoardViewChange}>
                            <option key={0} value={false}>{"Admin"}</option>
                            <option key={1} value={true}>{"Board"}</option>
                        </select>

                        
                        <img src={gear} className="settings-button" onClick={toggleSettingsDropdown} alt="gear"></img>
                        {settingsDropdown && (
                          <div className="settings-dropdown">
                            <button onClick={handleLogout}>Sign Out</button>
                          </div>
                        )}
                        
                       
                    </>
                )}
            </div>
        </header>
    );
};

export default Header;
