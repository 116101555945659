import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";

const formatDollars = (num) => `$${Math.round(num).toLocaleString()}`;

const OBJECT_CODES = {
  6111: "Regular Salaries",
  6112: "Administrators", 
  6121: "Substitute Salaries",
  6122: "Other Part-Time Salaries",
  6131: "Certificated Staff Supplemental Pay",
  6141: "Certificated Employees Unused Leave and/or Severance Pay"
};

const ActivityCompensationTable = ({
  districtCode,
  fiscalYear,
  medicareRate,
  certifiedRetirement,
  buildingCode,
  functionCode,
  onDataUpdate,
}) => {
  const [rows, setRows] = useState([]);
  const [educationalStatuses, setEducationalStatuses] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [objectCode, setObjectCode] = useState("");
  const [fund, setFund] = useState("");

  const determineObjectCode = (type, functionCode) => {
    const conditions = {
      salary: [
        { object: "6112", condition: [2211, 2411] },
        { object: "6131", condition: [1411, 1421] },
        { object: "6111" }, // Default
      ],
      health: [
        { object: "6240", condition: [2222] },
        { object: "6241" }, // Default
      ],
      retirement: [{ object: "6211" }], // Default
      medicare: [{ object: "6232" }], // Default
    };

    const typeConditions = conditions[type];
    if (!typeConditions) return null;

    for (const { object, condition } of typeConditions) {
      if (!condition || condition.includes(functionCode)) {
        return object;
      }
    }

    return null;
  };

  const [notification, setNotification] = useState("");

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  useEffect(() => {
    // Fetch activity rows from Firebase
    const fetchActivityData = async () => {
      try {
        const rowsRef = ref(
          database,
          `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/activity/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = Object.values(snapshot.val());
          setRows(data);
        }
      } catch (error) {
        console.error("Error loading activity data: ", error);
      }
    };

    fetchActivityData();
  }, [districtCode, fiscalYear, functionCode, buildingCode]);

  // Fetch Educational Status options
  useEffect(() => {
    const fetchEducationalStatuses = async () => {
      try {
        const careerLaddersRef = ref(
          database,
          `salarySchedules/${districtCode}/${fiscalYear}/certified/careerLadders`
        );
        const snapshot = await get(careerLaddersRef);
        if (snapshot.exists()) {
          const ladderNames = Object.values(snapshot.val()).map(
            (ladder) => ladder.name
          );
          setEducationalStatuses(ladderNames);
        }
      } catch (error) {
        console.error("Error fetching educational statuses: ", error);
      }
    };

    fetchEducationalStatuses();
  }, [districtCode, fiscalYear]);

  useEffect(() => {
    const totals = rows.reduce(
      (acc, row) => {
        acc.salary += row.calculatedValue || 0;
        acc.totalCost += row.totalCost || 0;
        return acc;
      },
      { salary: 0, totalCost: 0 }
    );

    if (onDataUpdate) {
      onDataUpdate(totals);
    }
  }, [rows]);

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setObjectCode(row.objectCode || determineObjectCode("salary", functionCode)); // Default object code
    setFund(row.fund || "SPECIAL_REVENUE");
    setModalOpen(true);
  };

  const saveDetails = async () => {
    if (!selectedRow) return;

    const updatedRows = rows.map((row) =>
      row.id === selectedRow.id
        ? {
            ...row,
            objectCode: objectCode || determineObjectCode("salary", functionCode),
            fund: fund || "SPECIAL_REVENUE",
            project: selectedRow.project || "00000",
            source: selectedRow.source || 3,
            hasChanged: true, // Ensure the row is marked as changed
          }
        : row
    );

    setRows(updatedRows);

    setModalOpen(false);
    await saveDataToFirebase(updatedRows);
  };

  const saveKeyTotals = async (rows, districtCode, fiscalYear, functionCode, buildingCode) => {
    try {
      if (rows.length === 0) {
        console.log("No rows to process for key totals.");
        return;
      }
  
      // Step 1: Clear the existing activity data
      const dynamicKeysRef = ref(
        database,
        `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}`
        );
        try {
            const snapshot = await get(dynamicKeysRef);
            if (snapshot.exists()) {
                const allKeys = Object.keys(snapshot.val());
                const buildingCodePrefix = `${buildingCode}-`;
                const keysToDelete = allKeys.filter((key) => key.startsWith(buildingCodePrefix));

                // Delete each key associated with the current building code
                const deletePromises = keysToDelete.map((key) =>
                    set(ref(database, `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${key}/activity`), null)
                );
                await Promise.all(deletePromises);
                console.log("Existing activity data cleared for building code:", buildingCode);
            }
        } catch (error) {
            console.error("Error finding or clearing existing dynamic keys:", error);
        }
  
      // Initialize an object to store aggregated totals by dynamicKey, fund, and component
      const aggregatedKeyFundTotals = {};
  
      // Step 2: Loop through rows to calculate totals
      rows.forEach((row) => {
        // Define the components and their respective costs
        const components = {
          salary: row.calculatedValue || 0,
          retirement: (row.calculatedValue || 0) * certifiedRetirement,
          medicare: (row.calculatedValue || 0) * medicareRate,
        };
  
        const fund = row.fund || "SPECIAL_REVENUE"; // Default fund if not provided
  
        // Process each component
        Object.entries(components).forEach(([key, value]) => {
          // Determine the object code for the component
          const objectCode =
            key === "salary"
              ? row.objectCode || determineObjectCode(key, functionCode) // Use modal-overwritten value for salary
              : determineObjectCode(key, functionCode); // Use dynamic determination for other components
  
          // Generate the dynamic key
          const dynamicKey = `${buildingCode}-${objectCode}-${row.source || 3}-${
            row.project || "00000"
          }`;
  
          // Initialize the structure for this dynamic key and fund if not already present
          if (!aggregatedKeyFundTotals[dynamicKey]) {
            aggregatedKeyFundTotals[dynamicKey] = {};
          }
          if (!aggregatedKeyFundTotals[dynamicKey][fund]) {
            aggregatedKeyFundTotals[dynamicKey][fund] = 0;
          }
  
          // Add the component's cost to the correct dynamic key and fund
          aggregatedKeyFundTotals[dynamicKey][fund] += value || 0;
        });
      });
  
      // Step 3: Write the aggregated totals to Firebase
      const writePromises = Object.entries(aggregatedKeyFundTotals).map(
        async ([dynamicKey, fundData]) => {
            const objectCode = dynamicKey.split("-")[1];
            const descriptionPath = `deseCodeMap/Object/${objectCode}/SUBCLASS_DESCRIPTION`;

            let description = "";
            try {
                const snapshot = await get(ref(database, descriptionPath));
                if (snapshot.exists()) {
                    description = snapshot.val();
                }
            } catch (error) {
                console.error(`Error fetching description for ${descriptionPath}:`, error);
            }

            const keyTotalRef = ref(
                database,
                `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${dynamicKey}/activity`
            );
            await update(keyTotalRef, {
                ...fundData,
                DESCRIPTION: description,
                isEditable: false,
                GROUP: "Expense",
            });
        }
    );

    await Promise.all(writePromises);
    console.log("Key totals saved successfully for each component.");
} catch (error) {
    console.error("Error saving key totals: ", error);
}
};

  useEffect(() => {
    const totals = rows.reduce(
      (acc, row) => {
        acc.salary += row.calculatedValue || 0;
        acc.totalCost += row.totalCost || 0;
        return acc;
      },
      { salary: 0, totalCost: 0 }
    );
  
    // Notify the parent
    if (onDataUpdate) {
      onDataUpdate(totals);
    }
  }, [rows]);
  

  const fetchBaseSalary = async () => {
    try {
      const salaryRef = ref(
        database,
        `salarySchedules/${districtCode}/${fiscalYear}/certified/baseSalary`
      );
      const snapshot = await get(salaryRef);
      if (snapshot.exists()) {
        return Number(snapshot.val()) || 0;
      }
      console.error("Base salary data is not available for the requested fiscal year.");
      return 0;
    } catch (error) {
      console.error("Error fetching base salary: ", error);
      return 0;
    }
  };

  const fetchCurrentSalary = async (educationalStatus, yearsInEducation) => {
    if (!educationalStatus || !yearsInEducation) return 0;

    try {
      const salaryRef = ref(
        database,
        `salarySchedules/${districtCode}/${fiscalYear}/certified/salarySchedule/${educationalStatus}/${yearsInEducation - 1}/salary`
      );
      const snapshot = await get(salaryRef);
      if (snapshot.exists()) {
        return Number(snapshot.val()) || 0;
      }
      console.error("No salary found for the given inputs");
      return 0;
    } catch (error) {
      console.error("Error fetching current salary: ", error);
      return 0;
    }
  };

  const addRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      {
        id: Date.now(),
        last: "",
        first: "",
        activityName: "",
        method: "Percent of Base (%)",
        inputValue: 0,
        educationalStatus: "",
        yearsInEducation: 0,
        calculatedValue: 0,
        totalCost: 0
      },
    ]);
  };
  

  const removeRow = async (id) => {
    try {
      const confirmDelete = window.confirm("Are you sure you want to delete this row?");
      if (!confirmDelete) return; // Exit if user cancels

      // Remove the specific row from Firebase
      const rowRef = ref(
        database,
        `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/activity/staff/${id}`
      );
      await set(rowRef, null);
  
      const updatedRows = rows.filter((row) => row.id !== id);
      setRows(updatedRows);
  
      if (updatedRows.length === 0) {
        // If no rows remain, clear all totals and dynamic keys
        const totalsRef = ref(
          database,
          `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/activity/totals`
        );
      
        const dynamicKeysRef = ref(
          database,
          `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}`
        );
      
        // Retrieve all keys for the function code
        const snapshot = await get(dynamicKeysRef);
        if (snapshot.exists()) {
          const allKeys = Object.keys(snapshot.val());
          const buildingCodePrefix = `${buildingCode}-`;
      
          // Filter keys that belong to the current building
          const keysToDelete = allKeys.filter((key) =>
            key.startsWith(buildingCodePrefix)
          );
      
          // Delete all keys for the building
          const deletePromises = keysToDelete.map((key) =>
            set(
              ref(
                database,
                `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${key}/activity`
              ),
              null
            )
          );
      
          await Promise.all([
            set(totalsRef, null), // Delete totals
            ...deletePromises, // Delete all dynamic keys for the building
          ]);
      
          console.log(`Cleared all key totals for building code: ${buildingCode}.`);
        } else {
          // If no keys exist for the function code, just clear totals
          await set(totalsRef, null);
          console.log(`No keys found for function code: ${functionCode}. Totals cleared.`);
        }
      }
       else {
        // If rows remain, recalculate totals and key totals
        const aggregated = updatedRows.reduce(
          (acc, row) => {
            const contract = row.salary || 0;
            const medicare = contract * medicareRate;
            const retirement = contract * certifiedRetirement;
  
            acc.salary += contract;
            acc.medicare += medicare;
            acc.retirement += retirement;
            acc.totalCost += contract + medicare + retirement;
            return acc;
          },
          {
            salary: 0,
            medicare: 0,
            retirement: 0,
            totalCost: 0,
          }
        );
  
        const totalsRef = ref(
          database,
          `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/activity/totals`
        );
        await set(totalsRef, aggregated);
  
        await saveKeyTotals(updatedRows, districtCode, fiscalYear, functionCode, buildingCode);
  
        console.log("Row removed, totals updated, and key totals recalculated.");
      }
    } catch (error) {
      console.error("Error deleting row or updating data: ", error);
    }
  };
  
  
  const updateRow = async (id, key, value) => {
    let updatedRows = [...rows];
    const rowIndex = updatedRows.findIndex((row) => row.id === id);
  
    if (rowIndex === -1) return; // Exit if the row doesn't exist
  
    // Update the specific field
    const updatedRow = { ...updatedRows[rowIndex], [key]: value, hasChanged: true };
  
    // Perform calculations
    let calculatedValue = 0;
    if (updatedRow.method === "Direct Salary Input ($)") {
      calculatedValue = parseFloat(updatedRow.inputValue) || 0;
    } else if (updatedRow.method === "Percent of Base (%)") {
      const baseSalary = await fetchBaseSalary();
      calculatedValue = (baseSalary * (updatedRow.inputValue / 100)) || 0;
    } else if (updatedRow.method === "Percent of Salary (%)") {
      const currentSalary = await fetchCurrentSalary(
        updatedRow.educationalStatus,
        Math.max(updatedRow.yearsInEducation, 1)
      );
      calculatedValue = (currentSalary * (updatedRow.inputValue / 100)) || 0;
    }
  
    const medicare = calculatedValue * medicareRate;
    const retirement = calculatedValue * certifiedRetirement;
    const totalCost = calculatedValue + medicare + retirement;
  
    // Update the row with calculated values
    updatedRows[rowIndex] = { ...updatedRow, calculatedValue, totalCost };
  
    // Set updated rows
    setRows(updatedRows);
  };
  
  const saveDataToFirebase = async (updatedRows = rows) => {
    try {
      // 1. Save individual staff data
      const saveRowPromises = updatedRows
        .filter((row) => row.hasChanged)
        .map((row) => {
          const rowRef = ref(
            database,
            `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/activity/staff/${row.id}`
          );
          return update(rowRef, row); // Use `update` for partial updates
        });
      await Promise.all(saveRowPromises);
  
      // Reset `hasChanged` flag for saved rows
      setRows((prevRows) =>
        prevRows.map((row) => (row.hasChanged ? { ...row, hasChanged: false } : row))
      );
  
      // 2. Calculate aggregate totals
      const aggregated = updatedRows.reduce(
        (acc, row) => {
          const contract = row.calculatedValue || 0;
          const medicare = contract * medicareRate;
          const retirement = contract * certifiedRetirement;
  
          acc.salary += row.calculatedValue || 0;
          acc.medicare += medicare;
          acc.retirement += retirement;
          acc.totalCost += contract + medicare + retirement;
          return acc;
        },
        {
          salary: 0,
          medicare: 0,
          retirement: 0,
          totalCost: 0,
        }
      );
  
      const aggregateRef = ref(
        database,
        `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/activity/totals`
      );
      await set(aggregateRef, aggregated);
  
      // 3. Save key totals
      await saveKeyTotals(updatedRows, districtCode, fiscalYear, functionCode, buildingCode);
  
      showNotification("Saved");
    } catch (error) {
      showNotification("Error saving activity data: ", error);
    }
  };
  

  return (
    <div className="staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Activity Roles</h3>
      <table className="staff-management-table">
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>Activity Name</th>
            <th>Compensation Method</th>
            <th>Compensation Amount ($/%)</th>
            <th>Educational Status</th>
            <th>Years in Education</th>
            <th>Salary</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (
            <tr key={row.id}>
            <td>
              <input
                type="text"
                value={row.last}
                onChange={(e) =>
                  updateRow(row.id, "last", e.target.value)
                }
                onBlur={() => {
                  if (row.hasChanged && row.calculatedValue > 0) saveDataToFirebase();
                }}
              />
            </td>
              <td>
                <input
                  type="text"
                  value={row.first}
                  onChange={(e) =>
                    updateRow(row.id, "first", e.target.value)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.calculatedValue > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.activityName}
                  onChange={(e) =>
                    updateRow(row.id, "activityName", e.target.value)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.calculatedValue > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <select
                  value={row.method}
                  onChange={(e) => updateRow(row.id, "method", e.target.value)}
                  onBlur={() => {
                    if (row.hasChanged && row.calculatedValue > 0) saveDataToFirebase();
                  }}
                >
                  <option value="Direct Salary Input ($)">Direct Salary Input ($)</option>
                  <option value="Percent of Base (%)">
                    Percent of Base (%)
                  </option>
                  <option value="Percent of Salary (%)">
                    Percent of Salary (%)
                  </option>
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={row.inputValue}
                  onChange={(e) => {
                    const value = e.target.value === "" ? 0 : parseFloat(e.target.value);
                    updateRow(row.id, "inputValue", value);
                  }}
                  onBlur={() => {
                    if (row.hasChanged && row.calculatedValue > 0) saveDataToFirebase();
                  }}
                  disabled={
                    row.method === "Percent of Salary (%)" &&
                    (!row.educationalStatus || row.yearsInEducation <= 0)
                  }
                />
              </td>
              <td>
                <select
                  value={row.educationalStatus}
                  onChange={(e) =>
                    updateRow(row.id, "educationalStatus", e.target.value)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.calculatedValue > 0) saveDataToFirebase();
                  }}
                  disabled={row.method !== "Percent of Salary (%)"}
                >
                  <option value="">Select</option>
                  {educationalStatuses.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={row.yearsInEducation}
                  onChange={(e) =>
                    updateRow(row.id, "yearsInEducation", parseInt(e.target.value, 10))
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.calculatedValue > 0) saveDataToFirebase();
                  }}
                  disabled={row.method !== "Percent of Salary (%)"}
                  min={1}
                />
              </td>
              <td>{formatDollars(row.calculatedValue)}</td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
                <button onClick={() => removeRow(row.id)}>Delete</button>
                <button onClick={() => openDetailsModal(row)}>Details</button>                
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="7">Totals</td>
            <td>{formatDollars(rows.reduce((sum, row) => sum + (row.calculatedValue || 0), 0))}</td>
            <td>{formatDollars(rows.reduce((sum, row) => sum + (row.totalCost || 0), 0))}</td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h4>Edit Object Code & Fund</h4>
            <label>
              Project:
              <input
                type="text"
                value={selectedRow?.project || "00000"}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    project: e.target.value,
                  }))
                }
              />
            </label>
            <label>
              Source:
              <input
                type="number"
                value={selectedRow?.source || 3}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    source: parseInt(e.target.value, 10),
                  }))
                }
              />
            </label>
            <label>
              Object Code For Salary:
              <select
                value={objectCode}
                onChange={(e) => setObjectCode(e.target.value)}
              >
                {Object.entries(OBJECT_CODES).map(([code, label]) => (
                  <option key={code} value={code}>
                    {code} - {label}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Fund:
              <select
                value={fund}
                onChange={(e) => setFund(e.target.value)}
              >
                <option value="GENERAL_FUND">GENERAL_FUND</option>
                <option value="SPECIAL_REVENUE">SPECIAL_REVENUE</option>
                <option value="CAPITAL_FUND">CAPITAL_FUND</option>
                <option value="DEBT_SERVICE_FUND">DEBT_SERVICE_FUND</option>
              </select>
            </label>
            <button onClick={saveDetails}>Save</button>
            <button onClick={() => setModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
      <button onClick={addRow} className="add-row-btn">
        Add Activity Role
      </button>

    </div>
  );
};

export default ActivityCompensationTable;