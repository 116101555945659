import React, { useState, useEffect } from 'react';
import Card from './Card';
import GeneralDrilldownTable from './GeneralDrilldownTable';
import '../styles/BudgetDash.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {assignLevels} from '../utils'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BudgetDash = ({ selectedDistrictInfo, boardView, dashboardData, drilldownData, deseCodeMap}) => {
  // Add LEVEL to drilldownData
  let levelDict = assignLevels(drilldownData, 'LABEL');
  drilldownData.forEach(item => item['LEVEL'] = levelDict[item.LABEL]);
  // Get the most recent year in the dataset
  const maxYear = Math.max(...Object.keys(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']]));

  // Helper functions
  const formatPrecision = (num, precision) => (num ? parseFloat(num).toFixed(precision) : 'N/A');
  const calcYoYchange = (num_old, num_new) => { return (100 * (num_new - num_old) / num_old).toFixed(0);};
  // States
  const [isPerStudent, setIsPerStudent] = useState(false);
  const [processedData, setProcessedData] = useState([]);
  const [selectedFunds, setSelectedFunds] = useState(['TOTAL_ALL_FUNDS']);
  const [selectedObjects, setSelectedObjects] = useState([]);

  const OBJECTS = [
    { code: 'CERTIFICATED_SALARIES_6110', label: 'Certified Salaries' },
    { code: 'NONCERTIFICATED_SALARIES_6150', label: 'Non-Certified Salaries' },
    { code: 'EMPLOYEE_BENEFITS_6200', label: 'Benefits' },
    { code: 'PURCHASED_SERVICES_6300', label: 'Purchased Services' },
    { code: 'SUPPLIES_6400', label: 'Supplies' },
    { code: 'CAPITAL_OUTLAY_6500', label: 'Capital Outlay' },
    { code: 'OTHER_OBJECTS_6600', label: 'Other Objects' },
  ];
  

  // Helper Functions
  function calculateAverage(array) {
    if (array.length === 0) {
      return 0;
    }
  
    const sum = array.reduce((acc, num) => acc + num, 0);
    return sum / array.length;
  }

  const getArrowDirection = (num) => {
    if (num > 0) return 'up';
    if (num < 0) return 'down';
    return 'flat';
  };

  function sortByDistrictName(data, name) {
    return data.sort((a, b) => {
        // Place elements with DISTRICT_NAME === name at the beginning
        if (a.DISTRICT_NAME === name && b.DISTRICT_NAME !== name) {
            return -1; // a comes first
        }
        if (a.DISTRICT_NAME !== name && b.DISTRICT_NAME === name) {
            return 1; // b comes first
        }
        // Maintain original order for others
        return 0;
    });
  }

  // Fetch and process data based on selected school
  selectedDistrictInfo['peer_INSTRUCTION_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_INSTRUCTION']));
  selectedDistrictInfo['peer_SUPPORT_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_SUPPORT']));
  selectedDistrictInfo['peer_NONINSTRUCTION_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_NONINSTRUCTION']));
  selectedDistrictInfo['peer_SALARIES_avg'] = calculateAverage(selectedDistrictInfo['peerGroupOptions']['IDs'].map(key => dashboardData[key][maxYear]['BUDGET_PERCENT_SALARIES_AND_BENEFITS']));
  
  // Initialize original data with backup of TOTAL_ALL_FUNDS and fund values
  useEffect(() => {
    const initialData = drilldownData.map(item => ({
      ...item,
      ORIGINAL_TOTAL_ALL_FUNDS: item.TOTAL_ALL_FUNDS,
      GENERAL_FUND: item.GENERAL_FUND || 0,
      SPECIAL_REVENUE: item.SPECIAL_REVENUE || 0,
      CAPITAL_PROJECTS: item.CAPITAL_PROJECTS || 0,
      DEBT_SERVICE: item.DEBT_SERVICE || 0,
    }));
    setProcessedData(initialData);
  }, [drilldownData]);

  useEffect(() => {
    if (!processedData || processedData.length === 0) return;

    const districtMap = {
        [selectedDistrictInfo['DISTRICT_NAME']]: selectedDistrictInfo['DISTRICT_COUNTY_CODE'],
        ...selectedDistrictInfo['peerGroupOptions']['IDs'].reduce((map, code, index) => {
            const name = selectedDistrictInfo['peerGroupOptions']['names'][index];
            map[name] = code;
            return map;
        }, {}),
    };

    // Calculate averages
    const averages = {};
    const peerDistricts = selectedDistrictInfo['peerGroupOptions']['IDs'];
    const relevantData = processedData.filter(item => peerDistricts.includes(districtMap[item.DISTRICT_NAME]));

    Object.keys(processedData[0]).forEach(key => {
        if (key !== 'DISTRICT_NAME' && key !== 'YEAR' && key !== 'LABEL') {
            averages[key] = calculateAverage(relevantData.map(item => item[key] || 0));
        }
    });

    // Create the "Average" district
    const averageDistrict = {
        DISTRICT_NAME: 'Average',
        YEAR: maxYear,
        LABEL: 'Average',
        ...averages,
    };

    // Update processed data with "Average" district
    const updatedData = processedData.map(item => {
        let updatedTotal;

        if (selectedFunds.includes('TOTAL_ALL_FUNDS')) {
            updatedTotal = item.ORIGINAL_TOTAL_ALL_FUNDS;
        } else if (selectedObjects.length > 0) {
            updatedTotal = selectedObjects.reduce((sum, obj) => sum + (item[obj] || 0), 0);
        } else {
            updatedTotal = selectedFunds.reduce((sum, fund) => sum + (item[fund] || 0), 0);
        }

        if (isPerStudent) {
            const districtCode = districtMap[item.DISTRICT_NAME];
            const year = item.YEAR;
            let januaryMembership =
                dashboardData[districtCode]?.[year]?.JANUARY_MEMBERSHIP ||
                dashboardData[districtCode]?.[year - 1]?.JANUARY_MEMBERSHIP;

            if (!januaryMembership) {
                console.warn(`JANUARY_MEMBERSHIP missing for district: ${districtCode}, year: ${year}`);
                return { ...item }; // Skip update if data is missing
            }

            updatedTotal = updatedTotal / januaryMembership;
        }

        return {
            ...item,
            TOTAL_ALL_FUNDS: updatedTotal,
        };
    });

    setProcessedData([...updatedData, averageDistrict]);
}, [isPerStudent, selectedFunds, selectedObjects, dashboardData, selectedDistrictInfo]);

  // Handle Per-Student Toggle
  // Handle Fund Filter and Per-Student Toggle
  useEffect(() => {
    if (!processedData || processedData.length === 0) return;

    const districtMap = {
      [selectedDistrictInfo['DISTRICT_NAME']]: selectedDistrictInfo['DISTRICT_COUNTY_CODE'],
      ...selectedDistrictInfo['peerGroupOptions']['IDs'].reduce((map, code, index) => {
        const name = selectedDistrictInfo['peerGroupOptions']['names'][index];
        map[name] = code;
        return map;
      }, {}),
    };

    const updatedData = processedData.map(item => {
      let updatedTotal;

      // Handle funds or objects
      if (selectedFunds.includes('TOTAL_ALL_FUNDS')) {
        updatedTotal = item.ORIGINAL_TOTAL_ALL_FUNDS;
      } else if (selectedObjects.length > 0) {
        updatedTotal = selectedObjects.reduce((sum, obj) => sum + (item[obj] || 0), 0);
      } else {
        updatedTotal = selectedFunds.reduce((sum, fund) => sum + (item[fund] || 0), 0);
      }

      // Apply per-student transformation if toggle is active
      if (isPerStudent) {
        const districtCode = districtMap[item.DISTRICT_NAME];
        const year = item.YEAR;
        let januaryMembership =
          dashboardData[districtCode]?.[year]?.JANUARY_MEMBERSHIP ||
          dashboardData[districtCode]?.[year - 1]?.JANUARY_MEMBERSHIP;

        if (!januaryMembership) {
          console.warn(`JANUARY_MEMBERSHIP missing for district: ${districtCode}, year: ${year}`);
          return { ...item }; // Skip update if data is missing
        }

        updatedTotal = updatedTotal / januaryMembership;
      }

      return {
        ...item,
        TOTAL_ALL_FUNDS: updatedTotal,
      };
    });

    setProcessedData(updatedData);
  }, [isPerStudent, selectedFunds, selectedObjects, dashboardData, selectedDistrictInfo]);

  // Handle Fund Filter Changes
  const handleFundChange = (event) => {
    const { value, checked } = event.target;
  
    setSelectedFunds((prevFunds) => {
      if (value === 'TOTAL_ALL_FUNDS') {
        // If TOTAL_ALL_FUNDS is toggled, deselect all others and select only TOTAL_ALL_FUNDS
        return checked ? ['TOTAL_ALL_FUNDS'] : [];
      } else {
        // If a specific fund is toggled, remove TOTAL_ALL_FUNDS and update other funds
        const updatedFunds = checked
          ? [...prevFunds.filter(fund => fund !== 'TOTAL_ALL_FUNDS'), value] // Add the selected fund
          : prevFunds.filter(fund => fund !== value); // Remove the deselected fund
  
        // If all four specific funds are selected, reset to TOTAL_ALL_FUNDS
        if (
          updatedFunds.includes('GENERAL_FUND') &&
          updatedFunds.includes('SPECIAL_REVENUE') &&
          updatedFunds.includes('CAPITAL_PROJECTS') &&
          updatedFunds.includes('DEBT_SERVICE')
        ) {
          return ['TOTAL_ALL_FUNDS'];
        }
  
        return updatedFunds;
      }
    });
  
    // Clear object selection when a fund is selected
    setSelectedObjects([]);
  };  

  // Handle Object Filter Changes
  const handleObjectChange = (event) => {
    const { value, checked } = event.target;

    setSelectedObjects((prevObjects) => {
      const updatedObjects = checked
        ? [...prevObjects, value]
        : prevObjects.filter(obj => obj !== value);

      // If all objects are selected, reset to TOTAL_ALL_FUNDS
      if (updatedObjects.length === OBJECTS.length) {
        setSelectedFunds(['TOTAL_ALL_FUNDS']);
        return [];
      }

      return updatedObjects;
    });

    // Clear funds when selecting an object
    setSelectedFunds([]);
  };
  
  if (!processedData || processedData.length === 0) return null;
  return (
    <div className="budget-dashboard">
      <div className="title">Budget Breakdown</div>
      <div className="dashboard-cards">
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Instruction"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_INSTRUCTION, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_INSTRUCTION,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_INSTRUCTION) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_INSTRUCTION_avg'],0) + '%' || '$0'}`}
          bgColor="#88b0d8"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_INSTRUCTION,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_INSTRUCTION))}`}
          definition="The percentage of the total budget spent on direct instruction, including teacher salaries, classroom materials, and other costs directly related to educating students."
          graphMetric="BUDGET_PERCENT_INSTRUCTION"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Support"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SUPPORT, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SUPPORT,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SUPPORT) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_SUPPORT_avg'],0) + '%' || '$0'}`}
          bgColor="#d8abc1"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SUPPORT,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SUPPORT))}`}
          definition="The percentage of the total budget spent on support services, including counseling, food service, library services, transportation, and other non-instructional staff and resources."
          graphMetric="BUDGET_PERCENT_SUPPORT"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Non-Instruction"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_NONINSTRUCTION, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_NONINSTRUCTION,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_NONINSTRUCTION) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_NONINSTRUCTION_avg'],0) + '%' || '$0'}`}
          bgColor="#87b6a7"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_NONINSTRUCTION,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_NONINSTRUCTION))}`}
          definition="The percentage of the total budget spent on non-instructional expenses such as administration, maintenance, and other operational costs."
          graphMetric="BUDGET_PERCENT_NONINSTRUCTION"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
        <Card
          defaultSchool={selectedDistrictInfo}
          title="Salary & Benefits"
          value={formatPrecision(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SALARIES_AND_BENEFITS, 0) + '%' || '$0'}
          YoY={calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SALARIES_AND_BENEFITS,
                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SALARIES_AND_BENEFITS) + '% YoY' || '0% YoY'}
          smallText={`Peers: ${formatPrecision(selectedDistrictInfo['peer_SALARIES_avg'],0) + '%' || '$0'}`}
          bgColor="#f79f79"
          arrowDirection={`${getArrowDirection(calcYoYchange(dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear-1].BUDGET_PERCENT_SALARIES_AND_BENEFITS,
                                                             dashboardData[selectedDistrictInfo['DISTRICT_COUNTY_CODE']][maxYear].BUDGET_PERCENT_SALARIES_AND_BENEFITS))}`}
          definition="The percentage of the operating (fund 1, fund 2) budget allocated to salaries and benefits for all district employees, including teachers, administrators, and support staff (Funds 6110, 6150, 6200 as share of budget)."
          graphMetric="BUDGET_PERCENT_SALARIES_AND_BENEFITS"
          yAxisTitle='Percent (%)'
          boardView={boardView}
          graphData={dashboardData}
        />
      </div>

      {/* Table Integration */}
      {!boardView && ( // Only render header and DrilldownTable when boardView is false
        <>
          <div className="toggle-container-budget-dash">
          {/* Per Student Toggle */}
          <div className="column-budget-dash per-student-toggle-budget-dash">
            <h3>Adjust Per January Membership</h3>
            <label>
              <input 
                type="checkbox" 
                checked={isPerStudent} 
                onChange={() => setIsPerStudent(!isPerStudent)} 
              />
              Per Student
            </label>
          </div>
          {/* Fund Filters */}
          <div className="column-budget-dash fund-toggles-budget-dash">
            <h3>Fund Filter</h3>
            <label>
              <input
                type="checkbox"
                value="TOTAL_ALL_FUNDS"
                checked={selectedFunds.includes('TOTAL_ALL_FUNDS')}
                onChange={handleFundChange}
              />
              Total
            </label>
            <label>
              <input
                type="checkbox"
                value="GENERAL_FUND"
                checked={selectedFunds.includes('GENERAL_FUND')}
                onChange={handleFundChange}
              />
              General
            </label>
            <label>
              <input
                type="checkbox"
                value="SPECIAL_REVENUE"
                checked={selectedFunds.includes('SPECIAL_REVENUE')}
                onChange={handleFundChange}
              />
              Teacher
            </label>
            <label>
              <input
                type="checkbox"
                value="CAPITAL_PROJECTS"
                checked={selectedFunds.includes('CAPITAL_PROJECTS')}
                onChange={handleFundChange}
              />
              Capital
            </label>
            <label>
              <input
                type="checkbox"
                value="DEBT_SERVICE"
                checked={selectedFunds.includes('DEBT_SERVICE')}
                onChange={handleFundChange}
              />
              Debt
            </label>
          </div>

          {/* Object Filters */}
          <div className="column-budget-dash object-toggles-budget-dash">
            <h3>Object Filter</h3>
            <div className="object-values-budget-dash">
              {OBJECTS.map((obj) => (
                <label key={obj.code}>
                  <input
                    type="checkbox"
                    value={obj.code}
                    checked={selectedObjects.includes(obj.code)}
                    onChange={handleObjectChange}
                  />
                  {obj.label}
                </label>
              ))}
            </div>
          </div>
        </div>
        <h3>Financial Details</h3>
          <div className="drilldownTableContainer">
            
            <GeneralDrilldownTable
              data={sortByDistrictName(processedData, selectedDistrictInfo['DISTRICT_NAME'])}
              tableDataFilters={`item.YEAR==='${maxYear}'`}
              tableDataFormats={{ 'TOTAL_ALL_FUNDS': 'dollars' }}
              rowKey="LABEL"
              columnKey="DISTRICT_NAME"
              valueLabel="TOTAL_ALL_FUNDS"
              rowLevelFunctions={{ graph: true, edit: false }}
              deseCodeMap={deseCodeMap}
            />
          </div>
        </>
      )}


    </div>
  );
};

export default BudgetDash;