import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { database } from './firebaseConfig';
import { ref, get } from "firebase/database";
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import BudgetDash from './components/BudgetDash';
import FacultyDash from './components/FacultyDash';
import StudentsDash from './components/StudentsDash';
import AcademicsDash from './components/AcademicsDash';
import RevenueDash from './components/RevenueDash';
import DebtDash from './components/DebtDash';
import BudgetBuilder from './components/BudgetBuilder';
import SalarySchedule from './components/Staff/SalarySchedule';
import SalaryScheduleClassified from './components/Staff/SalaryScheduleClassified';
import StaffManagement from './components/Staff/StaffManagement';
import Transportation from './components/Transportation';
import RevenueCalc from './components/Revenue/RevenueInputs';
import ChatBot from './components/ChatBot';
import Header from './components/Header';
import PeerSelection from './components/PeerSelection';
import AuthWrapper from './components/AuthWrapper';
import AuthenticationCard from './components/AuthenticationCard';
import LoadingPage from './components/LoadingPage';
import './App.css';
import MonthlyReport from './components/Reporting/MonthlyReport';
import ReviewModule from './components/Reporting/Review';
import {sumFieldByGroup, findClosestMatch} from './utils';

function App() {
    // State declarations
    const [isFirstLogin, setIsFirstLogin] = useState(true);
    const [uniqueDistrictNames, setUniqueDistrictNames] = useState([]);
    const [superAdmin, setSuperAdmin] = useState(false);
    const [userFound, setUserFound] = useState(false);
    const [appState, setAppState] = useState({
        isLoading: true,
        error: null,
        data: {
            activeComponent: 'Overview',
            selectedDistrict: 'Fayette R-III',
            selectedDistrictInfo: {},
            peerDistrictInfo: {},
            peerGroupOptions: [],
            selectedPeerGroup: 'Default',
            peerGroupData: {},
            boardView: false,
            districtMap: {},
            dashboardData: {},
            drilldownData: {},
            currentBudget: {},
            monthlyReport: {},
            deseCodeMap: {}
        }
    });

    function expandArray(data, deseCodeMap) {
        const result = [];
        data.forEach((item) => {
          // Iterate over the keys of each object
          for (const key in item) {
            // Check if the key is not 'DISTRICT_COUNTY_CODE' or 'YEAR'
            if (key !== 'DISTRICT_COUNTY_CODE' && key !== 'YEAR' && key !== 'DISTRICT_NAME') {
              // Push a new object to the result array with the desired structure
              result.push({
                DISTRICT_NAME: item.DISTRICT_NAME,
                YEAR: item.YEAR,
                LABEL: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${key.slice(0,4)} - ${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].DETAIL_DESCRIPTION}` : key,
                PARENT: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].SUBCLASS} - ${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].SUBCLASS_DESCRIPTION}` : key,
                GPARENT: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].CLASS} - ${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].CLASS_DESCRIPTION}` : key,
                GGPARENT: deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)]? `${deseCodeMap[item[key].GROUP.toLowerCase()][key.slice(0,4)].GROUP}` : key,
                TOTAL_ALL_FUNDS: item[key].TOTAL_ALL_FUNDS,
                CAPITAL_PROJECTS: item[key].CAPITAL_PROJECTS,
                SPECIAL_REVENUE: item[key].SPECIAL_REVENUE,
                GENERAL_FUND: item[key].GENERAL_FUND,
                DEBT_SERVICE: item[key].DEBT_SERVICE,
                CERTIFICATED_SALARIES_6110: item[key].CERTIFICATED_SALARIES_6110,
                NONCERTIFICATED_SALARIES_6150: item[key].NONCERTIFICATED_SALARIES_6150,
                EMPLOYEE_BENEFITS_6200: item[key].EMPLOYEE_BENEFITS_6200,
                PURCHASED_SERVICES_6300: item[key].PURCHASED_SERVICES_6300,
                SUPPLIES_6400: item[key].SUPPLIES_6400,
                CAPITAL_OUTLAY_6500: item[key].CAPITAL_OUTLAY_6500,
                OTHER_OBJECTS_6600: item[key].OTHER_OBJECTS_6600,
                AGGREGATE: true
              });
            }
          }
        });

        const parentSum = sumFieldByGroup(result, 
                                           ['DISTRICT_NAME', 'YEAR', 'PARENT'], 
                                           ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE',
                                            'CERTIFICATED_SALARIES_6110', 'NONCERTIFICATED_SALARIES_6150', 'EMPLOYEE_BENEFITS_6200',
                                            'PURCHASED_SERVICES_6300', 'SUPPLIES_6400', 'CAPITAL_OUTLAY_6500', 'OTHER_OBJECTS_6600'
                                           ]);
        
        let parent_result = parentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GPARENT
            }))

        const gparentSum = sumFieldByGroup(parent_result, 
            ['DISTRICT_NAME', 'YEAR', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE',
             'CERTIFICATED_SALARIES_6110', 'NONCERTIFICATED_SALARIES_6150', 'EMPLOYEE_BENEFITS_6200',
             'PURCHASED_SERVICES_6300', 'SUPPLIES_6400', 'CAPITAL_OUTLAY_6500', 'OTHER_OBJECTS_6600']);

        let gparent_result = gparentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: obj.GGPARENT
        }))

        const ggparentSum = sumFieldByGroup(gparent_result, 
            ['DISTRICT_NAME', 'YEAR', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE',
             'CERTIFICATED_SALARIES_6110', 'NONCERTIFICATED_SALARIES_6150', 'EMPLOYEE_BENEFITS_6200',
             'PURCHASED_SERVICES_6300', 'SUPPLIES_6400', 'CAPITAL_OUTLAY_6500', 'OTHER_OBJECTS_6600']);

        let ggparent_result = ggparentSum.map(obj => ({
        ...obj, // Spread the original object
        LABEL: obj.PARENT,
        PARENT: 'Self'
        }))
      
        return [result, parent_result, gparent_result, ggparent_result].flat();
      }
    

    // Helper function
    const getKeyByValue = (obj, value) => {
        return Object.keys(obj).find(key => obj[key] === value);
    };

    // Main data fetching function
    const initializeAppData = async (districtName = null) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
            
            // Store the district we want to use
            const targetDistrict = districtName || appState.data.selectedDistrict;

            // Fetch district map if we don't have it
            let districtMap = appState.data.districtMap;
            if (Object.keys(districtMap).length === 0) {
                const districtMapSnapshot = await get(ref(database, '/districtCodeMap'));
                if (!districtMapSnapshot.exists()) {
                    throw new Error('District map not found');
                }
                districtMap = districtMapSnapshot.val();
            }
            setUniqueDistrictNames([...new Set(Object.values(districtMap))])

            // Fetch functionCodeMap if we don't have it
            const functionCodeMapSnapshot = await get(ref(database, '/functionCodeMap_dev'));
            let functionCodeMap = functionCodeMapSnapshot.val();

            const deseCodeMapSnapshot = await get(ref(database, '/deseCodeMap_dev'));
            let deseCodeMap = deseCodeMapSnapshot.val();


            // Fetch peer groups using district map
            const selectedDistrictCode = getKeyByValue(districtMap, targetDistrict);
            const peerGroupsSnapshot = await get(ref(database, `/peerDefinitions/${selectedDistrictCode}`));
            if (!peerGroupsSnapshot.exists()) {
                throw new Error('Peer groups not found');
            }

            const peerGroups = peerGroupsSnapshot.val();
            const processedPeerGroups = {};

            // Process peer groups
            for (const key in peerGroups) {
                const ids = peerGroups[key];
                const names = ids.map(id => districtMap[id] || "Unknown");
                processedPeerGroups[key] = {
                    names,
                    IDs: ids
                };
            }

            // Fetch dashboard data for selected district and peers
            const selectedPeerGroup = 'Default';
            const peerIDs = processedPeerGroups[selectedPeerGroup].IDs;
            const dashboardDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/mainDashSummary/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const dashboardResults = await Promise.all(dashboardDataPromises);
            const dashboardData = Object.fromEntries(dashboardResults.filter(([_, data]) => data !== null));

            // Fetch drilldown data for selected district and peers
            const drilldownDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/expenseDrilldown/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const drilldownResults = await Promise.all(drilldownDataPromises);
            const drilldownDataJSON = Object.fromEntries(drilldownResults.filter(([_, data]) => data !== null));

            const drilldownDataArray = Object.entries(drilldownDataJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => ({
                  DISTRICT_COUNTY_CODE: key,
                  YEAR,
                  ...details
                }))
              );

            for(let i = 0; i<drilldownDataArray.length; i++){
                drilldownDataArray[i]['DISTRICT_NAME'] = drilldownDataArray[i]['1111']['DISTRICT_NAME'];
            }

            const drilldownData = expandArray(drilldownDataArray, deseCodeMap);

            ////////////////////
            // Fetch drilldown data for selected district and peers
            const currentBudgetPromises = [selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/budget/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });
            
            const currentResults = await Promise.all(currentBudgetPromises);
            const currentBudgetJSON = Object.fromEntries(currentResults.filter(([_, data]) => data !== null));
            
            const currentBudgetArray = Object.entries(currentBudgetJSON).flatMap(([ID, years]) =>
                Object.entries(years).flatMap(([YEAR, categories]) =>
                    Object.entries(categories).flatMap(([GROUP, accounts]) =>
                        Object.entries(accounts).flatMap(([accountCode, budget_observed]) =>
                            Object.entries(budget_observed)
                                .filter(([key]) => key === 'budget') // Filter for 'budget' key
                                .flatMap(([budgetObserved, versions]) =>
                                    Object.entries(versions).flatMap(([version, details]) => ({
                                        ID,
                                        YEAR,
                                        GROUP,
                                        accountCode,
                                        budgetObserved,
                                        version,
                                        ...details
                                    }))
                                )
                        )
                    )
                )
            );


            
            console.log('currentBudgetArray: ', currentBudgetArray)
            
            for (let i = 0; i < currentBudgetArray.length; i++) {
                currentBudgetArray[i]['DISTRICT_NAME'] = targetDistrict;
            }

            const fundMapping = {
                "10": "GENERAL_FUND",
                "20": "SPECIAL_REVENUE",
                "30": "DEBT_SERVICE",
                "40": "CAPITAL_PROJECTS"
              };

            const groupedData = {};

            currentBudgetArray.forEach(item => {

                let key = parseInt(item.accountCode.split('-')[1]);
                const groupData = deseCodeMap[item.GROUP] || {};
                // if the key doesn't exist, it is a custom function code 
                let customFuncCode = false;
                if(!groupData[key]){customFuncCode = true;}
                const keyData = (!customFuncCode? groupData[key] : groupData[findClosestMatch(Object.keys(groupData), key)]) || {};
                const objectCodeData = deseCodeMap['object'][parseInt(item.accountCode.split('-')[2])] || {};
                const fundKey = fundMapping[item.fund];
                const groupKey = `${item.YEAR}-${item.accountCode.slice(3,item.accountCode.length)}-${item.version}`;
                
                if (!groupedData[groupKey]) {
                    groupedData[groupKey] = {
                        ...item,
                        LABEL: item.accountCode.slice(8,item.accountCode.length),
                        FUNC_CODE_DESCRIPTION: !customFuncCode ? keyData.DETAIL_DESCRIPTION : "Unknown",
                        DESCRIPTION: objectCodeData.DETAIL_DESCRIPTION ? objectCodeData.DETAIL_DESCRIPTION : "Unknown",
                        IS_EDITABLE: keyData.IS_EDITABLE ? keyData.IS_EDITABLE : false,
                        PARENT: !customFuncCode ? `${key} - ${keyData.DETAIL_DESCRIPTION}` : `${key} - Unknown`,
                        GPARENT: keyData.SUBCLASS ? `${keyData.SUBCLASS} - ${keyData.SUBCLASS_DESCRIPTION}` : 'Unknown',
                        GGPARENT: keyData.CLASS ? `${keyData.CLASS} - ${keyData.CLASS_DESCRIPTION}` : 'Unknown',
                        GGGPARENT: keyData.GROUP? `${keyData.GROUP}` : item.GROUP,
                        GENERAL_FUND: 0,
                        SPECIAL_REVENUE: 0,
                        DEBT_SERVICE: 0,
                        CAPITAL_PROJECTS: 0,
                        TOTAL_ALL_FUNDS: 0,
                        AGGREGATE: false
                      }
                }
                if (fundKey) {
                    groupedData[groupKey][fundKey] = item.budget;
                    groupedData[groupKey]['TOTAL_ALL_FUNDS'] += item.budget;
                }
              
            })

            const current_budget_children = Object.entries(groupedData).map(([key, value]) => ({
                groupKey: key,
                ...value
              }));


            const parentSum = sumFieldByGroup(current_budget_children, 
                ['ID', 'YEAR', 'version', 'PARENT'], 
                ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE'
                ]);

            let parent_result = parentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GPARENT,
            AGGREGATE: true
            }))

            const gparentSum = sumFieldByGroup(parent_result, 
            ['ID', 'YEAR', 'version', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

            let gparent_result = gparentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GGPARENT,
            AGGREGATE: true
            }))

            const ggparentSum = sumFieldByGroup(gparent_result, 
            ['ID', 'YEAR', 'version', 'PARENT'], 
            ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);

            let ggparent_result = ggparentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: obj.GGGPARENT,
            AGGREGATE: true
            }))

            const gggparentSum = sumFieldByGroup(ggparent_result, 
                ['ID', 'YEAR', 'version', 'PARENT'], 
                ['TOTAL_ALL_FUNDS', 'GENERAL_FUND', 'CAPITAL_PROJECTS', 'SPECIAL_REVENUE', 'DEBT_SERVICE']);
    
            let gggparent_result = gggparentSum.map(obj => ({
            ...obj, // Spread the original object
            LABEL: obj.PARENT,
            PARENT: 'Self',
            AGGREGATE: true
            }))

            const currentBudget = [current_budget_children, parent_result, gparent_result, ggparent_result, gggparent_result].flat();

            /////////////////////

            const monthlyReportPromises = [selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/monthlyReport/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });
            
            const monthlyReportResults = await Promise.all(monthlyReportPromises);
            const monthlyReportJSON = Object.fromEntries(monthlyReportResults.filter(([_, data]) => data !== null));

            const monthlyReportArray = Object.entries(monthlyReportJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => {
                    return {
                        DISTRICT_COUNTY_CODE: key,
                        YEAR,
                        ANNUAL_BUDGET: details.ANNUAL_BUDGET || 0,
                        MONTH_TOTAL: details.MONTH_TOTAL || 0,
                        YEAR_TO_DATE: details.YEAR_TO_DATE || 0,
                        ...details
                    };
                })
            );
            
            for (let i = 0; i < monthlyReportArray.length; i++) {
                monthlyReportArray[i]['DISTRICT_NAME'] = targetDistrict;
            }

            let monthlyReport = []
            if(monthlyReportArray[0]) {
                Object.entries(monthlyReportArray[0]).forEach(item=> {
                if (!['DISTRICT_COUNTY_CODE', 'YEAR', 'ANNUAL_BUDGET', 'MONTH_TOTAL', 'YEAR_TO_DATE', 'DISTRICT_NAME'].includes(item[0])){
                    monthlyReport.push({
                        LABEL: item[0],
                        YEAR: '2025',
                        ANNUAL_BUDGET: item[1]['ANNUAL_BUDGET'],
                        MONTH_TOTAL: item[1]['MONTH_TOTAL'],
                        PARENT: item[1]['PARENT'],
                        YEAR_TO_DATE: item[1]['YEAR_TO_DATE'],
                    })
                }
            })
            }
            /////////////////////

            // Update all state at once
            setAppState(prev => ({
                isLoading: false,
                error: null,
                data: {
                    ...prev.data,
                    selectedDistrict: targetDistrict,
                    districtMap,
                    peerGroupData: processedPeerGroups,
                    peerGroupOptions: Object.keys(peerGroups),
                    selectedPeerGroup,
                    peerDistrictInfo: {
                        IDs: processedPeerGroups[selectedPeerGroup].IDs,
                        names: processedPeerGroups[selectedPeerGroup].names
                    },
                    selectedDistrictInfo: {
                        DISTRICT_NAME: targetDistrict,
                        DISTRICT_COUNTY_CODE: getKeyByValue(districtMap, targetDistrict),
                        peerGroupOptions: {
                            IDs: processedPeerGroups[selectedPeerGroup].IDs,
                            names: processedPeerGroups[selectedPeerGroup].names
                        },
                        selectedPeerGroup: selectedPeerGroup
                    },
                    dashboardData,
                    drilldownData,
                    currentBudget,
                    monthlyReport,
                    deseCodeMap
                }
            }));

        } catch (error) {
            console.error('Error initializing app:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    // Initial data load
    useEffect(() => {
        initializeAppData();
    }, []);

    const updateDistrict = async (newDistrict) => {
        // Only call `initializeAppData` if the district changes
        if (newDistrict !== appState.data.selectedDistrict) {
            await initializeAppData(newDistrict);
            setAppState(prev => ({
                ...prev,
                data: { ...prev.data, selectedDistrict: newDistrict }
            }));
        }
    };

    // Handle state changes
    const handleDistrictChange = async (event) => {
        const newDistrict = event.target.value;
        updateDistrict(newDistrict);
    };

    const handlePeerGroupChange = async (event) => {
        
        const newPeerGroup = event.target.value;

        // Update the state with the new peer group selection
        setAppState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                selectedPeerGroup: newPeerGroup,
                peerDistrictInfo: {
                    IDs: prev.data.peerGroupData[newPeerGroup].IDs,
                    names: prev.data.peerGroupData[newPeerGroup].names
                },
                selectedDistrictInfo: {
                    DISTRICT_NAME: prev.data.selectedDistrictInfo.DISTRICT_NAME,
                    DISTRICT_COUNTY_CODE: prev.data.selectedDistrictInfo.DISTRICT_COUNTY_CODE,
                    peerGroupOptions: {
                        IDs: prev.data.peerGroupData[newPeerGroup].IDs,
                        names: prev.data.peerGroupData[newPeerGroup].names
                    },
                },
            },
            
        }));

        

        // Fetch new dashboard data for the selected peer group
        await fetchDashboardDataForPeerGroup(newPeerGroup);
        await fetchDrilldownDataForPeerGroup(newPeerGroup);
    };

    const handleLogin = async (email) => {
        if (!isFirstLogin) return;

        const userRef = ref(database, `/userPermissions/`);
        const userSnapshot = await get(userRef);

        const userData = userSnapshot.val();
        const userPermissions = Object.values(userData).find(user => user.email === email.toLowerCase());

        if (userPermissions) {
            setUserFound(true);

            const { permissions } = userPermissions;

            if (permissions.district === 'All') {
                setSuperAdmin(true);
            } else if (appState.data.selectedDistrict !== permissions.district) {
                await initializeAppData(permissions.district);
            }
        }

        setIsFirstLogin(false);

        return;
    };
    
    const fetchDashboardDataForPeerGroup = async (peerGroup) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
    
            // Get the peer group IDs from state
            const peerIDs = appState.data.peerGroupData[peerGroup].IDs;
            const selectedDistrictCode = getKeyByValue(appState.data.districtMap, appState.data.selectedDistrict);
    
            // Fetch dashboard data for the selected district and peer group
            const dashboardDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/mainDashSummary/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });
    
            const dashboardResults = await Promise.all(dashboardDataPromises);
            const dashboardData = Object.fromEntries(dashboardResults.filter(([_, data]) => data !== null));
    
            // Update the state with the new dashboard data
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                data: {
                    ...prev.data,
                    dashboardData
                }
            }));
        } catch (error) {
            console.error('Error fetching dashboard data for peer group:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    const fetchDrilldownDataForPeerGroup = async (peerGroup) => {
        try {
            setAppState(prev => ({ ...prev, isLoading: true }));
    
            // Get the peer group IDs from state
            const peerIDs = appState.data.peerGroupData[peerGroup].IDs;
            const selectedDistrictCode = getKeyByValue(appState.data.districtMap, appState.data.selectedDistrict);
    
            // Fetch dashboard data for the selected district and peer group
            const drilldownDataPromises = [...peerIDs, selectedDistrictCode].map(async (ID) => {
                const snapshot = await get(ref(database, `/expenseDrilldown/${ID}`));
                return [ID, snapshot.exists() ? snapshot.val() : null];
            });

            const drilldownResults = await Promise.all(drilldownDataPromises);
            const drilldownDataJSON = Object.fromEntries(drilldownResults.filter(([_, data]) => data !== null));

            const drilldownDataArray = Object.entries(drilldownDataJSON).flatMap(([key, values]) => 
                Object.entries(values).map(([YEAR, details]) => ({
                  DISTRICT_COUNTY_CODE: key,
                  YEAR,
                  ...details
                }))
              );

            for(let i = 0; i<drilldownDataArray.length; i++){
                drilldownDataArray[i]['DISTRICT_NAME'] = drilldownDataArray[i]['1111']['DISTRICT_NAME'];
            }

            const deseCodeMapSnapshot = await get(ref(database, '/deseCodeMap_dev'));
            let deseCodeMap = deseCodeMapSnapshot.val();

            const drilldownData = expandArray(drilldownDataArray, deseCodeMap);


    
            // Update the state with the new dashboard data
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                data: {
                    ...prev.data,
                    drilldownData,
                    deseCodeMap
                }
            }));
        } catch (error) {
            console.error('Error fetching dashboard data for peer group:', error);
            setAppState(prev => ({
                ...prev,
                isLoading: false,
                error: error.message
            }));
        }
    };

    const handleBoardViewChange = (event) => {
        setAppState(prev => ({
            ...prev,
            data: { ...prev.data, boardView: event.target.value === 'true' }
        }));
    };

    const setActiveComponent = (component) => {
        setAppState(prev => ({
            ...prev,
            data: { ...prev.data, activeComponent: component }
        }));
    };

    const updatePeerGroupOptions = async (newOptions) => {
        // Update the state with the new peer group options
        setAppState(prev => ({
            ...prev,
            data: {
                ...prev.data,
                peerGroupOptions: newOptions
            }
        }));

        // Re-fetch data after updating peer group options
        await initializeAppData(); // You can pass the current selected district if needed
    };

    // Render loading state or error
    if (appState.isLoading) {
        return <LoadingPage/>;
    }

    if (appState.error) {
        return <div className="app-error">Error: {appState.error}</div>;
    }

    // Render component based on active selection
    const renderComponent = () => {
        switch (appState.data.activeComponent) {
            case 'Overview':
                return <Dashboard selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                  boardView={appState.data.boardView} 
                                  dashboardData={appState.data.dashboardData}/>;
            case 'Budget':
                return <BudgetDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                   boardView={appState.data.boardView} 
                                   dashboardData={appState.data.dashboardData}
                                   drilldownData={appState.data.drilldownData}
                                   deseCodeMap = {appState.data.deseCodeMap}
                                   />;
            case 'Faculty':
                return <FacultyDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={appState.data.boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Students':
                return <StudentsDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                     boardView={appState.data.boardView} 
                                     dashboardData={appState.data.dashboardData}/>;
            case 'Academics':
                return <AcademicsDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                      boardView={appState.data.boardView} 
                                      dashboardData={appState.data.dashboardData}/>;
            case 'Revenue':
                return <RevenueDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={appState.data.boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Debt':
                return <DebtDash selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                 boardView={appState.data.boardView} 
                                 dashboardData={appState.data.dashboardData}/>;
            case 'Salary':
            return <SalarySchedule selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={appState.data.boardView} 
                                dashboardData={appState.data.dashboardData}/>;
            case 'SalaryClassified':
                return <SalaryScheduleClassified selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={appState.data.boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Staff':
                return <StaffManagement selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={appState.data.boardView} 
                                    dashboardData={appState.data.dashboardData}/>;
            case 'Monthly':
            return <MonthlyReport selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={appState.data.boardView} 
                                dashboardData={appState.data.dashboardData}
                                currentBudget={appState.data.monthlyReport}/>;
            case 'Review':
                return <ReviewModule selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                    boardView={appState.data.boardView} 
                                    dashboardData={appState.data.dashboardData}
                                    currentBudget={appState.data.monthlyReport}/>;
            case 'Transport':
            return <Transportation selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={appState.data.boardView} 
                                dashboardData={appState.data.dashboardData}
                                currentBudget={appState.data.currentBudget}/>;
            case 'RevenueInput':
            return <RevenueCalc selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                boardView={appState.data.boardView} 
                                dashboardData={appState.data.dashboardData}
                                currentBudget={appState.data.currentBudget}/>;
            case 'Peers':
                return <PeerSelection selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                      updatePeerGroupOptions={updatePeerGroupOptions}/>;
            case 'BuildBudget':
                return <BudgetBuilder selectedDistrictInfo={appState.data.selectedDistrictInfo}
                                      drilldownData={appState.data.drilldownData}
                                      currentBudget={appState.data.currentBudget}
                                      deseCodeMap={appState.data.deseCodeMap}/>;
            default:
                return <Dashboard selectedDistrictInfo={appState.data.selectedDistrictInfo} 
                                  boardView={appState.data.boardView} 
                                  dashboardData={appState.data.dashboardData}/>;
        }
    };

    // Main render
    return (
       <Router>
          <Routes>
            {/* Public Route: Login */}
            <Route path="/login" element={<AuthenticationCard userFound={userFound} isFirstLogin={isFirstLogin}/>} />
            {/* Protected Routes */}
                <Route
                path="/"
                element={
                    <AuthWrapper onLogin={handleLogin} userFound={userFound} isFirstLogin={isFirstLogin}>
                    {userFound ? (
                        <div className="app">
                        <Header
                            selectedDistrict={appState.data.selectedDistrict}
                            selectedPeerGroup={appState.data.selectedPeerGroup}
                            peerGroupOptions={appState.data.peerGroupOptions}
                            boardView={appState.data.boardView}
                            superAdmin={superAdmin}
                            uniqueDistrictNames={uniqueDistrictNames.sort((a, b) => a.localeCompare(b))}
                            handleDistrictChange={handleDistrictChange}
                            handlePeerGroupChange={handlePeerGroupChange}
                            handleBoardViewChange={handleBoardViewChange}
                        />
                        <Sidebar
                            selectedDistrict={appState.data.selectedDistrict}
                            setActiveComponent={setActiveComponent}
                            activeComponent={appState.data.activeComponent}
                            boardView={appState.data.boardView}
                            superAdmin={superAdmin}
                        />
                        {renderComponent()}
                        {!appState.data.boardView && (
                            <ChatBot
                            selectedSchool={appState.data.selectedDistrict}
                            drilldownData={appState.data.drilldownData}
                            />
                        )}
                        </div>
                    ) : (
                        <Navigate to="/login" replace />
                    )}
                    </AuthWrapper>
                    }>
                </Route>
      </Routes>
    </Router>
            
    )
        
    
}

export default App;
