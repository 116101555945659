import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";

const formatDollars = (num) => `$${Math.round(num).toLocaleString('en-US')}`;

const OBJECT_CODES = {
  6151:	"Classified Salaries-Regular",
  6152:	"Instructional Aide Salaries",
  6153:	"Classified Substitute Salaries",
  6161:	"Classified Salaries - Part-Time",
  6171:	"Classified Employees Unused Leave and/or Severance Pay"
};

const ClassifiedStaffManagementTable = ({
  districtCode,
  fiscalYear,
  functionCode,
  buildingCode,
  ppoMonthly,
  lifeMonthly,
  medicareRate,
  socialSecurityRate,
  peers,
  onDataUpdate
}) => {
  const [Rows, setRows] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [objectCode, setObjectCode] = useState("");
  const [fund, setFund] = useState("");

  const determineObjectCode = (type, functionCode) => {
    const conditions = {
      salary: [
        { object: "6152", condition: [1111, 1131, 1151, 1191, 1221, 1281, 3512, 3812 ] },
        { object: "6161", condition: [2552, 1191] },
        { object: "6151"}, //default
      ],
      health: [
        { object: "6240", condition: [2222] },
        { object: "6241" }, // Default
      ],
      retirement: [{ object: "6221" }], // Default
      fica: [{ object: "6231" }], // Default
      medicare: [{ object: "6232" }], // Default
    };
  
    const typeConditions = conditions[type];
    if (!typeConditions) return null;
  
    for (const { object, condition } of typeConditions) {
      if (!condition || condition.includes(functionCode)) {
        return object;
      }
    }
  
    return null;
  };

  const [notification, setNotification] = useState("");

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setObjectCode(row.objectCode || determineObjectCode("salary", functionCode)); // Default object code for Classified Staff
    setFund(row.fund || "GENERAL_FUND");
    setModalOpen(true);
  };
  
  // Fetch categories from Firebase
  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesRef = ref(
        database,
        `salarySchedules/${districtCode}/${fiscalYear}/classified/salaryTable`
      );
      try {
        const snapshot = await get(categoriesRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const categoryEntries = await Promise.all(
            Object.keys(data).map(async (category) => {
              const detailsRef = ref(
                database,
                `salarySchedules/${districtCode}/${fiscalYear}/classified/salaryTable/${category}/1/details`
              );
              const detailsSnapshot = await get(detailsRef);
              const fullDetails = detailsSnapshot.exists() && detailsSnapshot.val()
            ? detailsSnapshot.val()
            : "No details available"; // Default message when no details are available
          const details =
            fullDetails.length > 40
              ? `${fullDetails.slice(0, 40)}...` // Add ellipsis if it exceeds 20 characters
              : fullDetails;
              return { name: category, details };
            })
          );
          setCategories(categoryEntries);
        }
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };
    fetchCategories();
  }, [districtCode, fiscalYear]);
  
  const fetchBaseHourlyPay = async (category, step) => {
    if (!category || step === null || step === undefined) return 0;

    try {
      const hourlyRateRef = ref(
        database,
        `salarySchedules/${districtCode}/${fiscalYear}/classified/salaryTable/${category}/${step}/hourlyRate`
      );
      const snapshot = await get(hourlyRateRef);
      if (snapshot.exists()) return Number(snapshot.val()) || 0;
      console.error("No hourly rate found for the given inputs");
      return 0;
    } catch (error) {
      console.error("Error fetching hourly rate: ", error);
      return 0;
    }
  };

  const addRow = () => {
    const newRow = {
      id: Date.now(),
      last: "",
      first: "",
      fte: 1,
      life: 0,
      health: 0,
      retire: 0,
      position: "",
      category: "",
      step: 1,
      hourlyRate: null,
      extra: 0,
      hours: 0,
      salary: 0,
      totalCost: 0,
    };
    setRows([...Rows, newRow]);
  };

  const updateRow = async (id, key, value) => {
    // Update the local state first
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.id === id);
  
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        const updatedValue =
          key === "step" && value < 1
            ? 1
            : key === "hours" || key === "fte"
            ? !isNaN(value) ? parseFloat(value) : 0
            : value;
  
        updatedRows[rowIndex] = { ...row, [key]: updatedValue, hasChanged: true };
      }
  
      return updatedRows;
    });
  
    // Handle specific keys for recalculation
    if (["category", "step"].includes(key)) {
      const updatedRow = Rows.find((row) => row.id === id);
  
      // Fetch the hourly rate from Firebase
      const hourlyRate = await fetchBaseHourlyPay(
        key === "category" ? value : updatedRow.category,
        key === "step" ? Math.max(value, 1) : updatedRow.step
      );
  
      // Update the state to reflect the fetched hourly rate
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        const rowIndex = updatedRows.findIndex((row) => row.id === id);
        if (rowIndex !== -1) {
          updatedRows[rowIndex] = {
            ...updatedRows[rowIndex],
            hourlyRate, // Update the hourlyRate field with the fetched value
          };
        }
        return updatedRows;
      });
  
      calculateFields(id, hourlyRate);
    } else if (key === "hourlyRate") {
      // If the hourlyRate is directly modified by the user
      calculateFields(id, value);
    } else if (["fte", "life", "health", "retire", "hours"].includes(key)) {
      const updatedRow = Rows.find((row) => row.id === id);
      const hourlyRate =
        updatedRow.hourlyRate !== null
          ? updatedRow.hourlyRate
          : await fetchBaseHourlyPay(updatedRow.category, updatedRow.step);
  
      calculateFields(id, hourlyRate);
    }
  };
  
  

  const calculateFields = (id, baseHourlyPay) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.id === id);
  
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        const hourlyRate = row.hourlyRate !== null ? row.hourlyRate : baseHourlyPay; // Prioritize manual input
        const extra = (row.extra || 0)
        const salary = (row.hours || 0) * baseHourlyPay + extra;
        const ppoCost = ppoMonthly * 12 * row.fte * (row.health || 0);
        const lifeCost = lifeMonthly * 12 * row.fte * (row.life || 0);
        const fica = salary * socialSecurityRate;
        const medicare = salary * medicareRate;
        const retirement = (salary + ppoCost) * peers * row.retire;
        const totalCost = salary + ppoCost + lifeCost + fica + medicare + retirement;
  
        updatedRows[rowIndex] = {
          ...row,
          salary,
          ppoCost,
          lifeCost,
          fica,
          medicare,
          retirement,
          totalCost,
        };
      }
  
      // Aggregate totals for the building
      const aggregated = updatedRows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          return acc;
        },
        { fte: 0, salary: 0, totalCost: 0 }
      );
  
      // Notify parent with recalculated totals
      onDataUpdate?.(aggregated);
  
      return updatedRows;
    });
  };

  const saveKeyTotals = async (Rows, districtCode, fiscalYear, functionCode, buildingCode) => {
    try {
      if (Rows.length === 0) {
        console.log("No rows to process for key totals.");
        return;
      }
  
      // Step 1: Clear the existing classified data
      const dynamicKeysRef = ref(
        database,
        `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}`
        );
        try {
            const snapshot = await get(dynamicKeysRef);
            if (snapshot.exists()) {
                const allKeys = Object.keys(snapshot.val());
                const buildingCodePrefix = `${buildingCode}-`;
                const keysToDelete = allKeys.filter((key) => key.startsWith(buildingCodePrefix));

                // Delete each key associated with the current building code
                const deletePromises = keysToDelete.map((key) =>
                    set(ref(database, `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${key}/classified`), null)
                );
                await Promise.all(deletePromises);
                console.log("Existing classified data cleared for building code:", buildingCode);
            }
        } catch (error) {
            console.error("Error finding or clearing existing dynamic keys:", error);
        }
  
      // Initialize an object to store aggregated totals by dynamicKey, fund, and component
      const aggregatedKeyFundTotals = {};
  
      // Step 2: Loop through rows to calculate totals
      Rows.forEach((row) => {
        // Define the components and their respective costs
        const components = {
          salary: row.salary || 0,
          health:
            ppoMonthly * 12 * (row.fte || 0) * (row.health || 0) +
            lifeMonthly * 12 * (row.fte || 0) * (row.life || 0),
          retirement: (row.salary || 0) * peers,
          medicare: (row.salary || 0) * medicareRate,
        };
  
        const fund = row.fund || "GENERAL_FUND"; // Default fund if not provided
  
        // Process each component
        Object.entries(components).forEach(([key, value]) => {
          // Determine the object code for the component
          const objectCode =
            key === "salary"
              ? row.objectCode || determineObjectCode(key, functionCode) // Use modal-overwritten value for salary
              : determineObjectCode(key, functionCode); // Use dynamic determination for other components
  
          // Generate the dynamic key
          const dynamicKey = `${buildingCode}-${objectCode}-${row.source || 1}-${
            row.project || "00000"
          }`;
  
          // Initialize the structure for this dynamic key and fund if not already present
          if (!aggregatedKeyFundTotals[dynamicKey]) {
            aggregatedKeyFundTotals[dynamicKey] = {};
          }
          if (!aggregatedKeyFundTotals[dynamicKey][fund]) {
            aggregatedKeyFundTotals[dynamicKey][fund] = 0;
          }
  
          // Add the component's cost to the correct dynamic key and fund
          aggregatedKeyFundTotals[dynamicKey][fund] += value || 0;
        });
      });
  
      // Step 3: Write the aggregated totals to Firebase
      const writePromises = Object.entries(aggregatedKeyFundTotals).map(
        async ([dynamicKey, fundData]) => {
            const objectCode = dynamicKey.split("-")[1];
            const descriptionPath = `deseCodeMap/Object/${objectCode}/SUBCLASS_DESCRIPTION`;
            console.log("descriptionPath",descriptionPath)
            // Fetch DESCRIPTION from Firebase
            const descriptionRef = ref(database, descriptionPath);
            let description = "";
            try {
                const snapshot = await get(descriptionRef);
                if (snapshot.exists()) {
                    description = snapshot.val();
                }
            } catch (error) {
                console.error(`Error fetching description for ${descriptionPath}:`, error);
            }

            const keyTotalRef = ref(
                database,
                `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${dynamicKey}/classified`
            );
            await update(keyTotalRef, {
                ...fundData,
                DESCRIPTION: description,
                IS_EDITABLE: false,
                GROUP: "Expense",
            });
        }
    );

    await Promise.all(writePromises);
    console.log("Key totals saved successfully for each component.");
} catch (error) {
    console.error("Error saving key totals: ", error);
}
};
  
const saveDataToFirebase = async (rows = Rows) => {
  try {
      // 1. Save individual staff data
      const saveRowPromises = rows.filter((row) => row.hasChanged).map((row) => {
        const rowRef = ref(
          database,
          `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/classified/staff/${row.id}`
        );
        return update(rowRef, row); // Save only changed rows
      });
      await Promise.all(saveRowPromises);    
      // Reset `hasChanged` flag for all rows
      setRows((prevRows) =>
        prevRows.map((row) => ({ ...row, hasChanged: false }))
      );  
      console.log("Saved Staff");
      // 2. Calculate aggregated totals
      const aggregated = rows.reduce(
        (acc, row) => {
          acc.fte += row.fte || 0;
          acc.extra += row.extra || 0;
          acc.salary += row.salary || 0;
          acc.totalCost += row.totalCost || 0;
          acc.hours += row.hours || 0;
          acc.ppoCost += row.ppoCost; //ppoMonthly * 12 * (row.fte || 0) * (row.health || 0);
          acc.lifeCost += row.lifeCost;//lifeMonthly * 12 * (row.fte || 0) * (row.life || 0);
          acc.medicare += row.medicare;//(row.salary || 0) * medicareRate;
          acc.fica += row.fica;//(row.salary || 0) * socialSecurityRate;
          acc.retirement += row.retirement;//(row.salary || 0 + row.ppoCost ||0) * peers * row.retire;
          return acc;
        },
        {
          fte: 0,
          extra: 0,
          salary: 0,
          totalCost: 0,
          hours: 0,
          ppoCost: 0,
          lifeCost: 0,
          medicare: 0,
          fica: 0,
          retirement: 0,
        }
      );

      // Save aggregated totals
      const totalsRef = ref(
        database,
        `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/classified/totals`
      );
      await set(totalsRef, { ...aggregated, key: "Totals" });
      console.log("Saved Table Totals");
      // 3. Save keyTotals with dynamic keys
      // Call the dynamic key writing function
      await saveKeyTotals(rows, districtCode, fiscalYear, functionCode, buildingCode);
      console.log("Saved Key Totals");
      showNotification("Saved");
    } catch (error) {
      showNotification("Error saving classified data: ", error);
    }
  };

  const saveDetails = async () => {
    if (!selectedRow) return;
  
    // Update rows and save to Firebase after the state update completes
    setRows((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.id === selectedRow.id
          ? {
              ...row,
              objectCode: objectCode || determineObjectCode("salary", functionCode),
              fund: fund || "GENERAL_FUND",
              project: selectedRow.project || "00000",
              source: selectedRow.source || 1,
              hasChanged: true, // Ensure the row is marked as changed
            }
          : row
      );
  
      // Save to Firebase using the updated state
      saveDataToFirebase(updatedRows);
      return updatedRows;
    });
  
    setModalOpen(false);
  };
  
  
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        // Fetch individual staff rows
        const rowsRef = ref(
          database,
          `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/classified/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const rows = Object.values(data).map((row) => ({
            ...row,
            objectCode: row.objectCode || "",
            fund: row.fund || "GENERAL_FUND",
            project: row.project || "00000",
            source: row.source || 1,
          }));
          setRows(rows);          
        }
      } catch (error) {
        console.error("Error loading staff data: ", error);
      }
    };
  
    fetchStaffData();
  }, [districtCode, fiscalYear]);
  
  
  return (
    <div className="staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Classified Roles</h3>
      <table className="staff-management-table">
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>FTE</th>
            <th>Life</th>
            <th>Health</th>
            <th>Retire</th>
            <th>Position</th>
            <th>Category</th>
            <th>Category Step</th>
            <th>Hourly Rate</th>
            <th>Hours</th>
            <th>Extra</th>
            <th>Salary</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Rows.map((row) => (
            <tr key={row.id}>
              <td>
                <input className="tableInput"
                  type="text"
                  value={row.last}
                  onChange={(e) => updateRow(row.id, "last", e.target.value)}
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="text"
                  value={row.first}
                  onChange={(e) => updateRow(row.id, "first", e.target.value)}
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.fte}
                  onChange={(e) =>
                    updateRow(row.id, "fte", parseFloat(e.target.value))
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="checkbox"
                  checked={!!row.life}
                  onChange={(e) =>
                    updateRow(row.id, "life", e.target.checked ? 1 : 0)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={!!row.health}
                  onChange={(e) =>
                    updateRow(row.id, "health", e.target.checked ? 1 : 0)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={!!row.retire}
                  onChange={(e) =>
                    updateRow(row.id, "retire", e.target.checked ? 1 : 0)
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) => updateRow(row.id, "position", e.target.value)}
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
              <select
                value={row.category} className="tableInput"
                onChange={(e) => updateRow(row.id, "category", e.target.value)}
                onBlur={() => {
                  if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                }}
              >
                <option value="">Select</option>
                {categories.map((category) => (
                  <option key={category.name} value={category.name}>
                    {`${category.name}: ${category.details}`}
                  </option>
                ))}
              </select>
              </td>
              <td>
                <input
                  type="number"
                  value={row.step}
                  onChange={(e) =>
                    updateRow(row.id, "step", parseInt(e.target.value, 10))
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  className="tableInput"
                  type="number"
                  value={row.hourlyRate || ""}
                  onChange={(e) =>
                    updateRow(row.id, "hourlyRate", parseFloat(e.target.value))
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.hours}
                  onChange={(e) =>
                    updateRow(row.id, "hours", parseFloat(e.target.value))
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input className="tableInput"
                  type="number"
                  value={row.extra}
                  onChange={(e) =>
                    updateRow(row.id, "extra", parseFloat(e.target.value))
                  }
                  onBlur={() => {
                    if (row.hasChanged && row.salary > 0) saveDataToFirebase();
                  }}
                />
              </td>
              <td>{formatDollars(row.salary)}</td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
              <button
                onClick={async () => {
                  try {
                    const confirmDelete = window.confirm("Are you sure you want to delete this row?");
                    if (!confirmDelete) return;

                    // Step 1: Delete the row from Firebase
                    const rowRef = ref(
                      database,
                      `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/classified/staff/${row.id}`
                    );
                    await set(rowRef, null);

                    // Step 2: Remove the row from the local state
                    const updatedRows = Rows.filter((r) => r.id !== row.id);
                    setRows(updatedRows);

                    if (updatedRows.length === 0) {
                      // Step 3a: If no rows remain, clear totals and classified key totals in Firebase
                      const totalsRef = ref(
                        database,
                        `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/classified/totals`
                      );
                    
                      const dynamicKeysRef = ref(
                        database,
                        `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}`
                      );
                    
                      // Retrieve all keys under the function code
                      const snapshot = await get(dynamicKeysRef);
                      if (snapshot.exists()) {
                        const allKeys = Object.keys(snapshot.val());
                        const buildingCodePrefix = `${buildingCode}-`;
                    
                        // Filter keys that belong to the current building
                        const keysToDelete = allKeys.filter((key) =>
                          key.startsWith(buildingCodePrefix)
                        );
                    
                        // Clear classified values for relevant keys
                        const deletePromises = keysToDelete.map((key) =>
                          set(
                            ref(
                              database,
                              `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${key}/classified`
                            ),
                            null
                          )
                        );
                    
                        await Promise.all([
                          set(totalsRef, null), // Delete totals
                          ...deletePromises, // Delete classified key values for the building
                        ]);
                    
                        console.log(
                          `All classified key values cleared for building code: ${buildingCode}.`
                        );
                      } else {
                        // If no keys exist for the function code, just clear totals
                        await set(totalsRef, null);
                        console.log(`No keys found for function code: ${functionCode}. Totals cleared.`);
                      }
                    
                      // Notify parent of empty totals
                      onDataUpdate?.({
                        fte: 0,
                        salary: 0,
                        totalCost: 0,
                      });
                    
                      console.log("All rows deleted. Totals and classified values cleared.");
                    }
                     else {
                      // Step 3b: Recalculate totals and save updated data to Firebase
                      const aggregated = updatedRows.reduce(
                        (acc, row) => {
                          acc.fte += row.fte || 0;
                          acc.extra += row.extra ||0;
                          acc.salary += row.salary || 0;
                          acc.totalCost += row.totalCost || 0;
                          acc.hours += row.hours || 0;
                          acc.ppoCost += row.ppoCost || 0;
                          acc.lifeCost += row.lifeCost || 0;
                          acc.medicare += row.medicare || 0;
                          acc.fica += row.fica || 0;
                          acc.retirement += row.retirement || 0;
                          return acc;
                        },
                        {
                          fte: 0,
                          extra: 0,
                          salary: 0,
                          totalCost: 0,
                          hours: 0,
                          ppoCost: 0,
                          lifeCost: 0,
                          medicare: 0,
                          fica: 0,
                          retirement: 0,
                        }
                      );

                      const totalsRef = ref(
                        database,
                        `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/classified/totals`
                      );
                      await set(totalsRef, { ...aggregated, key: "Totals" });

                      // Update key totals dynamically
                      await saveKeyTotals(updatedRows, districtCode, fiscalYear, functionCode, buildingCode);

                      // Notify parent with updated totals
                      onDataUpdate?.(aggregated);

                      console.log("Row deleted. Totals and key totals updated.");
                    }
                  } catch (error) {
                    console.error("Error deleting row: ", error);
                  }
                }}
              >
                Delete
              </button>
              <button onClick={() => openDetailsModal(row)} className="details-btn" >Details</button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
    <tr>
      <td colSpan={2}>Totals:</td>
      <td>{Rows.reduce((acc, row) => acc + (row.fte || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.life || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.health || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.retire || 0), 0)}</td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>{Rows.reduce((acc, row) => acc + (row.hours || 0), 0)}</td>
      <td>{Rows.reduce((acc, row) => acc + (row.extra || 0), 0)}</td>
      <td>{formatDollars(Rows.reduce((acc, row) => acc + (row.salary || 0), 0))}</td>
      <td>{formatDollars(Rows.reduce((acc, row) => acc + (row.totalCost || 0), 0))}</td>
      <td></td>
    </tr>
  </tfoot>
      </table>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
          <h4>Edit Object Code & Fund</h4>
            <label>
              Project:
              <input
                type="text"
                value={selectedRow?.project || "00000"}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    project: e.target.value,
                  }))
                }
              />
            </label>
            <label>
              Source:
              <input
                type="number"
                value={selectedRow?.source || 1}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    source: parseInt(e.target.value, 10),
                  }))
                }
              />
            </label>
            <label>
              Object Code For Salary:
              <select
                value={objectCode}
                onChange={(e) => setObjectCode(e.target.value)}
              >
                {Object.entries(OBJECT_CODES).map(([code, label]) => (
                  <option key={code} value={code}>
                    {code} - {label}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Fund For Total Compensation:
              <select value={fund} onChange={(e) => setFund(e.target.value)}>
                <option value="GENERAL_FUND">GENERAL_FUND</option>
                <option value="SPECIAL_REVENUE">SPECIAL_REVENUE</option>
                <option value="CAPITAL_FUND">CAPITAL_FUND</option>
                <option value="DEBT_SERVICE_FUND">DEBT_SERVICE_FUND</option>
              </select>
            </label>
            <button onClick={saveDetails}>Save</button>
            <button onClick={() => setModalOpen(false)}>Cancel</button>
          </div>
        </div>
      )}
      <button onClick={addRow} className ="add-row-btn" >Add Classified Role</button>

    </div>
  );
};

export default React.memo(ClassifiedStaffManagementTable);

