import React, { useState, useEffect, useRef } from 'react';
import '../styles/ChatBot.css';
import ReactMarkdown from 'react-markdown';

const ChatBot = ({selectedSchool, drilldownData}) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [spreadsheetData, setSpreadsheetData] = useState(null);
  const [initialPromptLoaded, setInitialPromptLoaded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);  // New state for typing indicator
  const textareaRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const API_BASE_URL = process.env.NODE_ENV === 'production' 
  ? 'https://app.balancehq.com' 
  : 'http://localhost:5001';

  const toggleChatbot = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setSpreadsheetData(drilldownData.filter(item=>(item.DISTRICT_NAME===selectedSchool)&(item.YEAR==='2023')))
  }, [selectedSchool, drilldownData]);
  

  useEffect(() => {
    if (spreadsheetData && !initialPromptLoaded) {
      const initialPrompt = `
        You are an experienced school CFO & Superintendent with a background in banking & public k12 education, you value efficiency, student performance, and making the most of tax-payer dollars. 
        The superintendent of a Missouri school is coming to you with questions about their school district budget. Your job is to respond to their questions based on your experience and pulling information from the spreadsheet to form your opinions. 
        Success is in developing a narrative of how trends in a school compare to peers that the superintendent can share with their school board.
        Don't speak frankly, be kind & gentle in how you communicate the informaiton, coming off as optimistic and realistic, your answers are clear but concise & to the point.
        If you're going to tell them to look deeper into something, do it for them if the data is available.
        A budget of too much excess or too much deficit can both be signs of sub-optimal budgeting. 
        When you analyze numbers in a school budget, make sure to review the numbers in comparison to other aspects of budget & performance and assess how they interact with and are informed by each other.
        The following data contains Object codes and their descriptions defined by DESE:
        
        {
    "6111": {
        "DETAIL_DESCRIPTION": "Regular Salaries"
    },
    "6112": {
        "DETAIL_DESCRIPTION": "Administrators"
    },
    "6121": {
        "DETAIL_DESCRIPTION": "Substitute Salaries"
    },
    "6122": {
        "DETAIL_DESCRIPTION": "Other Part-Time Salaries"
    },
    "6131": {
        "DETAIL_DESCRIPTION": "Certificated Staff Supplemental Pay"
    },
    "6141": {
        "DETAIL_DESCRIPTION": "Certificated Employees Unused Leave andor Severance Pay"
    },
    "6151": {
        "DETAIL_DESCRIPTION": "Classified Salaries-Regular"
    },
    "6152": {
        "DETAIL_DESCRIPTION": "Instructional Aide Salaries"
    },
    "6153": {
        "DETAIL_DESCRIPTION": "Classified Substitute Salaries"
    },
    "6161": {
        "DETAIL_DESCRIPTION": "Classified Salaries - Part-Time"
    },
    "6171": {
        "DETAIL_DESCRIPTION": "Classified Employees Unused Leave andor Severance Pay"
    },
    "6211": {
        "DETAIL_DESCRIPTION": "Teachers Retirement"
    },
    "6221": {
        "DETAIL_DESCRIPTION": "Non-Teacher Retirement"
    },
    "6231": {
        "DETAIL_DESCRIPTION": "Old Age Survivor and Disability Insurance OASDI"
    },
    "6232": {
        "DETAIL_DESCRIPTION": "Medicare"
    },
    "6241": {
        "DETAIL_DESCRIPTION": "Employee Insurance"
    },
    "6261": {
        "DETAIL_DESCRIPTION": "Workers Compensation Insurance"
    },
    "6271": {
        "DETAIL_DESCRIPTION": "Unemployment Compensation"
    },
    "6291": {
        "DETAIL_DESCRIPTION": "Other Employer-Provided Benefits"
    },
    "6311": {
        "DETAIL_DESCRIPTION": "Purchased Instructional Services"
    },
    "6312": {
        "DETAIL_DESCRIPTION": "Instructional Program Improvement Services"
    },
    "6313": {
        "DETAIL_DESCRIPTION": "Pupil Services"
    },
    "6314": {
        "DETAIL_DESCRIPTION": "Staff Services"
    },
    "6315": {
        "DETAIL_DESCRIPTION": "Audit Services"
    },
    "6316": {
        "DETAIL_DESCRIPTION": "Data Processing and Technology Related Services"
    },
    "6317": {
        "DETAIL_DESCRIPTION": "Legal Services"
    },
    "6318": {
        "DETAIL_DESCRIPTION": "Election Services"
    },
    "6319": {
        "DETAIL_DESCRIPTION": "Other Professional Services"
    },
    "6321": {
        "DETAIL_DESCRIPTION": "Subawards Under Subagrements - First 25000"
    },
    "6322": {
        "DETAIL_DESCRIPTION": "Subawards Under Subagrements - In Excess of 25000"
    },
    "6331": {
        "DETAIL_DESCRIPTION": "Cleaning Services"
    },
    "6332": {
        "DETAIL_DESCRIPTION": "Repairs and Maintenance"
    },
    "6333": {
        "DETAIL_DESCRIPTION": "Rentals - Land and Buildings"
    },
    "6334": {
        "DETAIL_DESCRIPTION": "Rentals - Equipment"
    },
    "6335": {
        "DETAIL_DESCRIPTION": "Water and Sewer"
    },
    "6336": {
        "DETAIL_DESCRIPTION": "Trash Removal"
    },
    "6337": {
        "DETAIL_DESCRIPTION": "Technology-Related Repairs and Maintenance"
    },
    "6338": {
        "DETAIL_DESCRIPTION": "Rentals of Computers and Related Equipment"
    },
    "6339": {
        "DETAIL_DESCRIPTION": "Other Property Services"
    },
    "6341": {
        "DETAIL_DESCRIPTION": "Contracted Pupil Transportation To and From School"
    },
    "6342": {
        "DETAIL_DESCRIPTION": "Other Contracted Pupil Transportation Non-Route"
    },
    "6343": {
        "DETAIL_DESCRIPTION": "Travel"
    },
    "6349": {
        "DETAIL_DESCRIPTION": "Other Transportation Services"
    },
    "6351": {
        "DETAIL_DESCRIPTION": "Property Insurance"
    },
    "6352": {
        "DETAIL_DESCRIPTION": "Liability Insurance"
    },
    "6353": {
        "DETAIL_DESCRIPTION": "Fidelity Bond Premiums"
    },
    "6359": {
        "DETAIL_DESCRIPTION": "Judgments Against LEA and Settlements"
    },
    "6361": {
        "DETAIL_DESCRIPTION": "Communication"
    },
    "6362": {
        "DETAIL_DESCRIPTION": "Advertising"
    },
    "6363": {
        "DETAIL_DESCRIPTION": "Printing and Binding"
    },
    "6371": {
        "DETAIL_DESCRIPTION": "Dues and Memberships"
    },
    "6391": {
        "DETAIL_DESCRIPTION": "Other Purchased Services"
    },
    "6398": {
        "DETAIL_DESCRIPTION": "Other Expenses"
    },
    "6411": {
        "DETAIL_DESCRIPTION": "General Supplies"
    },
    "6412": {
        "DETAIL_DESCRIPTION": "Supplies - Technology - Related"
    },
    "6431": {
        "DETAIL_DESCRIPTION": "Textbook"
    },
    "6441": {
        "DETAIL_DESCRIPTION": "Library Books"
    },
    "6451": {
        "DETAIL_DESCRIPTION": "Resource Materials"
    },
    "6461": {
        "DETAIL_DESCRIPTION": "Warehouse Inventory Adjustments"
    },
    "6471": {
        "DETAIL_DESCRIPTION": "Food Supplies"
    },
    "6481": {
        "DETAIL_DESCRIPTION": "Electric"
    },
    "6482": {
        "DETAIL_DESCRIPTION": "Gas - Natural"
    },
    "6483": {
        "DETAIL_DESCRIPTION": "Gas - LP"
    },
    "6484": {
        "DETAIL_DESCRIPTION": "Fuel Oil"
    },
    "6485": {
        "DETAIL_DESCRIPTION": "Coal"
    },
    "6486": {
        "DETAIL_DESCRIPTION": "GasolineDiesel"
    },
    "6491": {
        "DETAIL_DESCRIPTION": "Other Supplies and Materials"
    },
    "6511": {
        "DETAIL_DESCRIPTION": "Land"
    },
    "6521": {
        "DETAIL_DESCRIPTION": "Buildings"
    },
    "6531": {
        "DETAIL_DESCRIPTION": "Improvements Other than Buildings"
    },
    "6541": {
        "DETAIL_DESCRIPTION": "Regular Equipment"
    },
    "6542": {
        "DETAIL_DESCRIPTION": "Equipment - Classroom Instructional Apparatus"
    },
    "6543": {
        "DETAIL_DESCRIPTION": "Technology - Related Hardware"
    },
    "6544": {
        "DETAIL_DESCRIPTION": "Technology Software"
    },
    "6551": {
        "DETAIL_DESCRIPTION": "Vehicles - Except School Buses"
    },
    "6552": {
        "DETAIL_DESCRIPTION": "Pupil Transportation Vehicles - School Buses"
    },
    "6591": {
        "DETAIL_DESCRIPTION": "Other Capital Outlay"
    },
    "6611": {
        "DETAIL_DESCRIPTION": "Principal - Bonded Indebtedness"
    },
    "6612": {
        "DETAIL_DESCRIPTION": "Principal - Short Term Loans"
    },
    "6613": {
        "DETAIL_DESCRIPTION": "Principal - Lease Purchase Agreements"
    },
    "6614": {
        "DETAIL_DESCRIPTION": "Principal - Long Term Loans"
    },
    "6621": {
        "DETAIL_DESCRIPTION": "Interest - Bonded Indebtedness"
    },
    "6622": {
        "DETAIL_DESCRIPTION": "Interest - Short Term Loans"
    },
    "6623": {
        "DETAIL_DESCRIPTION": "Interest - Lease Purchase Agreements"
    },
    "6624": {
        "DETAIL_DESCRIPTION": "Interest - Long Term Loans"
    },
    "6631": {
        "DETAIL_DESCRIPTION": "Fees - Bonded Indebtedness"
    },
    "6632": {
        "DETAIL_DESCRIPTION": "Fees - Short Term Loans"
    },
    "6633": {
        "DETAIL_DESCRIPTION": "Fees - Lease Purchase Agreements"
    },
    "6634": {
        "DETAIL_DESCRIPTION": "Fees - Long Term Loans"
    }
}
      `;

      setMessages([{ role: 'system', content: initialPrompt }]);
      setInitialPromptLoaded(true);
    }
  }, [spreadsheetData, initialPromptLoaded]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  const handleSend = async () => {
    if (input.trim()) {
      setMessages([...messages, { role: 'user', content: input }]);
      setInput('');
      setIsTyping(true);  // Show typing indicator

      console.log('input: ', JSON.stringify({ messages: [...messages, { role: 'user', content: input }] }))
      try {
        const response = await fetch(`${API_BASE_URL}/api/chatbot`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ messages: [...messages, { role: 'user', content: input }] }),
        });
        const data = await response.json();
        setMessages(prev => [...prev, { role: 'assistant', content: data.reply }]);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setIsTyping(false);  // Hide typing indicator
      }
    }
  };

  return (
    <div className={`chatbot-container ${isOpen ? 'open' : 'closed'}`}>
      <div className="chatbot-header" onClick={toggleChatbot}>
        <span>{isOpen ? 'Ask Linda' : 'Ask Linda'}</span>
        <span className="chatbot-minimizer">{isOpen ? '-' : ''}</span>
      </div>
      {isOpen && (
        <div className="chatbot-messages">
          {messages.map((message, index) => (
            message.role !== 'system' && (
              <div key={index} className={`chatbot-message ${message.role}`}>
                <ReactMarkdown>{message.content}</ReactMarkdown>
              </div>
            )
          ))}
          {isTyping && (
            <div className="typing-indicator">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
          <textarea
            ref={textareaRef}
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSend()}
            rows={1}
            className="input-textarea"
            placeholder="Type Here . . ."
          />
          <button onClick={handleSend}>Send</button>
        </div>
      )}
    </div>
  );
};

export default ChatBot;
