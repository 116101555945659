import React, { useState, useEffect } from "react";
import { database } from "../../firebaseConfig";
import { ref, get, set, update } from "firebase/database";
import "../../styles/StaffManagement.css";

const formatDollars = (num) => `$${Math.round(num).toLocaleString()}`;

const OBJECT_CODES = {
  6111: "Regular Cert Salaries",
  6112: "Administrator Salaries", 
  6121: "Substitute Salaries",
  6122: "Other Part-Time Cert Salaries",
  6131: "Cert Staff Supplemental Pay",
  6141: "Cert Employees Unused Leave and/or Severance Pay"
};

const CertifiedStaffManagementTable = ({
    districtCode,
    fiscalYear,
    ppoMonthly,
    lifeMonthly,
    medicareRate,
    buildingCode,
    functionCode,
    certifiedRetirement,
    onDataUpdate,
  }) => {

  const [Rows, setRows] = useState([]);
  const [careerSteps, setcareerSteps] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [objectCode, setObjectCode] = useState("");
  const [fund, setFund] = useState("");

  const determineObjectCode = (type, functionCode) => {
    const conditions = {
      salary: [
        { object: "6112", condition: [2211, 2411] },
        { object: "6131", condition: [1411, 1421] },
        { object: "6111" }, // Default
      ],
      health: [
        { object: "6240", condition: [2222] },
        { object: "6241" }, // Default
      ],
      retirement: [{ object: "6211" }], // Default
      medicare: [{ object: "6232" }], // Default
    };

    const typeConditions = conditions[type];
    if (!typeConditions) return null;

    for (const { object, condition } of typeConditions) {
      if (!condition || condition.includes(functionCode)) {
        return object;
      }
    }

    return null;
  };

  const [notification, setNotification] = useState("");

  const showNotification = (message) => {
    setNotification(message);
    setTimeout(() => setNotification(""), 3000); // Clear the notification after 3 seconds
  };

  const openDetailsModal = (row) => {
    setSelectedRow(row);
    setObjectCode(row.objectCode || determineObjectCode("salary", functionCode));
    setFund(row.fund || "SPECIAL_REVENUE");
    setModalOpen(true);
  };

  const saveDetails = async () => {
    if (!selectedRow) return;

    const updatedRows = Rows.map((row) =>
      row.id === selectedRow.id
        ? {
            ...row,
            objectCode: objectCode || determineObjectCode("salary", functionCode),
            fund: fund || "SPECIAL_REVENUE",
            project: selectedRow.project || "00000",
            source: selectedRow.source || 3,
            hasChanged: true, // Ensure the row is marked as changed
          }
        : row
    );

    setRows(updatedRows);
    setModalOpen(false);
    await saveDataToFirebase();
  };

  // Fetch career ladders from Firebase
  useEffect(() => {
    const fetchcareerSteps = async () => {
      const careerStepsRef = ref(
        database,
        `salarySchedules/${districtCode}/${fiscalYear}/certified/careerLadders`
      );
      try {
        const snapshot = await get(careerStepsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const ladderNames = Object.values(data).map((ladder) => ladder.name);
          setcareerSteps(ladderNames);
        }
      } catch (error) {
        console.error("Error fetching career steps: ", error);
      }
    };
    fetchcareerSteps();
  }, [districtCode,fiscalYear]); // Add fiscalYear if it can change

  const fetchSalary = async (educationalStatus, yearsInEducation) => {
    if (!educationalStatus || yearsInEducation === null || yearsInEducation === undefined)
      return 0;

    try {
      const salaryRef = ref(
        database,
        `salarySchedules/${districtCode}/${fiscalYear}/certified/salarySchedule/${educationalStatus}/${yearsInEducation-1}/salary`
      );
      const snapshot = await get(salaryRef);
      if (snapshot.exists()) return Number(snapshot.val()) || 0;
      console.error("No salary found for the given inputs");
      return 0;
    } catch (error) {
      console.error("Error fetching salary: ", error);
      return 0;
    }
  };

  const addRow = () => {
    const newRow = {
      id: Date.now(),
      last: "",
      first: "",
      fte: 1,
      life: 0,
      health: 0,
      position: "",
      educationalStatus: "",
      yearsInEducation: 1,
      salary: 0,
      extendedContract: 0,
      careerLadder: 0,
      totalCost: 0
    };
    setRows([...Rows, newRow]);
  };

  const updateRow = async (id, key, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.id === id);
  
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        const updatedValue =
          key === "yearsInEducation" && value < 1
            ? 1
            : key === "fte" || key === "extendedContract" || key === "careerLadder"
            ? !isNaN(value) ? parseFloat(value) : 0
            : value;
  
        updatedRows[rowIndex] = { 
          ...row, 
          [key]: updatedValue,
          hasChanged: true, // Mark the row as changed
        };
      }
  
      return updatedRows;
    });
  
    const updatedRow = Rows.find((row) => row.id === id);
    const baseSalary =
      key === "educationalStatus" || key === "yearsInEducation"
        ? await fetchSalary(
            key === "educationalStatus" ? value : updatedRow.educationalStatus,
            key === "yearsInEducation"
              ? Math.max(value, 1)
              : updatedRow.yearsInEducation
          )
        : updatedRow.salary / (updatedRow.fte || 1); // Fetch base salary if needed
  
    calculateFields(id, baseSalary);
  };

  const calculateFields = (id, baseSalary) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      const rowIndex = updatedRows.findIndex((row) => row.id === id);
  
      if (rowIndex !== -1) {
        const row = updatedRows[rowIndex];
        const effectiveSalary = baseSalary * (row.fte || 1); // Calculate effective salary
        const contract = effectiveSalary + parseFloat(row.extendedContract || 0) + parseFloat(row.careerLadder || 0);
        const ppoCost = ppoMonthly * 12 * row.fte * (row.health || 0);
        const lifeCost = lifeMonthly * 12 * row.fte * (row.life || 0);
        const medicare = contract * medicareRate;
        const retirement = (contract + ppoCost) * certifiedRetirement;
        const totalCost = contract + ppoCost + lifeCost + medicare + retirement;
  
        updatedRows[rowIndex] = {
          ...row,
          salary: effectiveSalary, // Update salary to reflect FTE
          totalCost,
        };
      }
  
      return updatedRows;
    });
  };

  const saveKeyTotals = async (Rows, districtCode, fiscalYear, functionCode, buildingCode) => {
    try {
      // Step 1: Clear the existing certified data
      const dynamicKeysRef = ref(
        database,
        `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}`
      );
      try {
        const snapshot = await get(dynamicKeysRef);
        if (snapshot.exists()) {
          const allKeys = Object.keys(snapshot.val());
          const buildingCodePrefix = `${buildingCode}-`;
          const keysToDelete = allKeys.filter((key) => key.startsWith(buildingCodePrefix));
  
          // Delete each key associated with the current building code
          const deletePromises = keysToDelete.map((key) =>
            set(ref(database, `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${key}/certified`), null)
          );
          await Promise.all(deletePromises);
          console.log("Existing certified data cleared for building code:", buildingCode);
        }
      } catch (error) {
        console.error("Error finding or clearing existing dynamic keys:", error);
      }
  
      // If Rows is empty, exit here since no data to aggregate
      if (Rows.length === 0) {
        console.log("Rows is empty. All keys cleared.");
        return;
      }
  
      // Initialize an object to store aggregated totals by dynamicKey, fund, and component
      const aggregatedKeyFundTotals = {};
  
      // Step 2: Loop through rows to calculate totals
      Rows.forEach((row) => {
        // Define the components and their respective costs
        const components = {
          salary: row.salary || 0,
          health:
          ppoMonthly * 12 * (row.fte || 0) * (row.health || 0) +
          lifeMonthly * 12 * (row.fte || 0) * (row.life || 0),
          retirement: ((row.salary || 0) + (row.extendedContract || 0) + (row.careerLadder || 0)) * certifiedRetirement,
          medicare: ((row.salary || 0) + (row.extendedContract || 0) + (row.careerLadder || 0)) * medicareRate,
        };
  
        const fund = row.fund || "SPECIAL_REVENUE"; // Default fund if not provided
  
        // Process each component
        Object.entries(components).forEach(([key, value]) => {
          // Determine the object code for the component
          const objectCode =
            key === "salary"
              ? row.objectCode || determineObjectCode(key, functionCode) // Use modal-overwritten value for salary
              : determineObjectCode(key, functionCode); // Use dynamic determination for other components
  
          // Generate the dynamic key
          const dynamicKey = `${buildingCode}-${objectCode}-${row.source || 3}-${
            row.project || "00000"
          }`;
  
          // Initialize the structure for this dynamic key and fund if not already present
          if (!aggregatedKeyFundTotals[dynamicKey]) {
            aggregatedKeyFundTotals[dynamicKey] = {};
          }
          if (!aggregatedKeyFundTotals[dynamicKey][fund]) {
            aggregatedKeyFundTotals[dynamicKey][fund] = 0;
          }
  
          // Add the component's cost to the correct dynamic key and fund
          aggregatedKeyFundTotals[dynamicKey][fund] += value || 0;

          // If the component is 'medicare' and fund is 'SPECIAL_REVENUE',
          // also add the value to 'SPECIAL_REVENUE_CERTIFIED'
          if (key === "medicare" && fund === "SPECIAL_REVENUE") {
            if (!aggregatedKeyFundTotals[dynamicKey]["SPECIAL_REVENUE_CERTIFIED"]) {
              aggregatedKeyFundTotals[dynamicKey]["SPECIAL_REVENUE_CERTIFIED"] = 0;
            }
            aggregatedKeyFundTotals[dynamicKey]["SPECIAL_REVENUE_CERTIFIED"] += value || 0;
          }
        });
      });
  
      // Step 3: Write the aggregated totals to Firebase
      const writePromises = Object.entries(aggregatedKeyFundTotals).map(
        async ([dynamicKey, fundData]) => {
            const objectCode = dynamicKey.split("-")[1];
            const descriptionPath = `deseCodeMap/Object/${objectCode}/SUBCLASS_DESCRIPTION`;

            // Fetch DESCRIPTION from Firebase
            const descriptionRef = ref(database, descriptionPath);
            let description = "";
            try {
                const snapshot = await get(descriptionRef);
                if (snapshot.exists()) {
                    description = snapshot.val();
                }
            } catch (error) {
                console.error(`Error fetching description for ${descriptionPath}:`, error);
            }

            const keyTotalRef = ref(
                database,
                `currentBudgetBeta_dev/${districtCode}/${fiscalYear}/${functionCode}/${dynamicKey}/certified`
            );
            await update(keyTotalRef, {
                ...fundData,
                DESCRIPTION: description,
                isEditable: false,
                GROUP: "Expense",
            });
        }
    );

    await Promise.all(writePromises);
    console.log("Key totals saved successfully for each component.");
} catch (error) {
    console.error("Error saving key totals: ", error);
}
};
  
  // Main save function
  const saveDataToFirebase = async () => {
    try {
      // 1. Save individual staff data
      const saveRowPromises = Rows.filter((row) => row.hasChanged).map((row) => {
        const rowRef = ref(
          database,
          `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/certified/staff/${row.id}`
        );
        return update(rowRef, row); // Use update instead of set for partial updates
      });
      await Promise.all(saveRowPromises);
  
      // 2. Calculate aggregate totals
      const aggregated = Rows.reduce(
        (acc, row) => {
          const contract = (row.salary || 0) + (row.extendedContract || 0) + (row.careerLadder || 0);
          const ppoCost = ppoMonthly * 12 * row.fte * (row.health || 0);
          const lifeCost = lifeMonthly * 12 * row.fte * (row.life || 0);
          const medicare = contract * medicareRate;
          const retirement = (contract + ppoCost) * certifiedRetirement;
  
          acc.fte += row.fte || 0;
          acc.salary += row.salary || 0;
          acc.extendedContract += row.extendedContract || 0;
          acc.careerLadder += row.careerLadder || 0;
          acc.totalCost += contract + ppoCost + lifeCost + medicare + retirement;
          acc.ppoCost += ppoCost;
          acc.lifeCost += lifeCost;
          acc.medicare += medicare;
          acc.retirement += retirement;
          return acc;
        },
        {
          fte: 0,
          salary: 0,
          extendedContract: 0,
          careerLadder: 0,
          totalCost: 0,
          ppoCost: 0,
          lifeCost: 0,
          medicare: 0,
          retirement: 0,
        }
      );
  
      const aggregateRef = ref(
        database,
        `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/certified/totals`
      );
      await set(aggregateRef, aggregated);
  
      // 3. Save key totals
      await saveKeyTotals(Rows, districtCode, fiscalYear, functionCode, buildingCode);
      
      // Reset `hasChanged` for all rows
      setRows((prevRows) =>
        prevRows.map((row) => ({ ...row, hasChanged: false }))
      );

      showNotification("Saved");
    } catch (error) {
      showNotification("Error saving activity data: ", error);
    }
  };
  
  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        // Fetch individual staff rows
        const rowsRef = ref(
          database,
          `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/certified/staff`
        );
        const snapshot = await get(rowsRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          const rows = Object.values(data); // Convert the fetched object into an array
          setRows(rows); // Populate the table rows
        }
      } catch (error) {
        console.error("Error loading staff data: ", error);
      }
    };
  
    fetchStaffData();
  }, [districtCode, fiscalYear, functionCode]);  

  useEffect(() => {
    const aggregated = Rows.reduce(
      (acc, row) => {
        acc.fte += row.fte || 0;
        acc.salary += row.salary || 0;
        acc.totalCost += row.totalCost || 0;
        return acc;
      },
      { fte: 0, salary: 0, totalCost: 0 }
    );
  
    onDataUpdate(aggregated); // Notify parent
  }, [Rows]); // Only re-run when `Rows` changes
  

  return (
    <div className="staff-table-container">
      {notification && (
      <div className="notification">
        {notification}
      </div>
    )}
      <h3>Certified Roles</h3>
      <table className="staff-management-table">
        <thead>
          <tr>
            <th>Last</th>
            <th>First</th>
            <th>FTE</th>
            <th>Life</th>
            <th>Health</th>
            <th>Position</th>
            <th>Education</th>
            <th>Step</th>
            <th>Salary</th>
            <th>Extended Contract</th>
            <th>Career Ladder</th>
            <th>Total Cost</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Rows.map((row) => (
            <tr key={row.id}>
              <td>
                <input
                  type="text"
                  value={row.last}
                  onChange={(e) =>
                    updateRow(row.id, "last", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.first}
                  onChange={(e) =>
                    updateRow(row.id, "first", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.fte}
                  min="0"
                  max="1"
                  step="0.01"
                  onChange={(e) => {
                    let value = parseFloat(e.target.value);

                    // Auto-correct values outside the range [0, 1]
                    if (value > 1) value = 1;
                    if (value < 0) value = 0;

                    // Update the input field and application state
                    e.target.value = value; // Correct the displayed value
                    updateRow(row.id, "fte", value);
                  }}
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={!!row.life}
                  onChange={(e) => {
                    updateRow(row.id, "life", e.target.checked ? 1 : 0);
                    if (row.educationalStatus) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  checked={!!row.health}
                  onChange={(e) => {
                    updateRow(row.id, "health", e.target.checked ? 1 : 0);
                    if (row.educationalStatus) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) =>
                    updateRow(row.id, "position", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <select
                  value={row.educationalStatus}
                  onChange={(e) =>
                    updateRow(row.id, "educationalStatus", e.target.value)
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                >
                  <option value="">Select</option>
                  {careerSteps.map((ladder) => (
                    <option key={ladder} value={ladder}>
                      {ladder}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="number"
                  value={row.yearsInEducation}
                  onChange={(e) =>
                    updateRow(row.id, "yearsInEducation", parseInt(e.target.value, 10))
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              
              <td>{formatDollars(row.salary)}</td>
              <td>
                <input
                  type="number"
                  value={row.extendedContract}
                  onChange={(e) =>
                    updateRow(row.id, "extendedContract", parseFloat(e.target.value))
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>
                <input
                  type="number"
                  value={row.careerLadder}
                  onChange={(e) =>
                    updateRow(row.id, "careerLadder", parseFloat(e.target.value))
                  }
                  onBlur={() => {
                    if (row.educationalStatus && row.hasChanged) saveDataToFirebase();
                  }}
                />
              </td>
              <td>{formatDollars(row.totalCost)}</td>
              <td>
              <button
                onClick={async () => {
                  const updatedRows = Rows.filter((r) => r.id !== row.id);
                  const confirmDelete = window.confirm(
                    "Are you sure you want to delete this row?"
                  );
                  if (!confirmDelete) return;

                  // Remove the specific row from Firebase
                  const rowRef = ref(
                    database,
                    `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/certified/staff/${row.id}`
                  );
                  await set(rowRef, null);

                  // Initialize aggregated totals
                  let aggregated = {
                    fte: 0,
                    salary: 0,
                    extendedContract: 0,
                    careerLadder: 0,
                    totalCost: 0,
                    retirement: 0,
                    medicare: 0,
                    benefits: 0,
                  };

                  // If no rows remain, remove the totals node
                  if (updatedRows.length === 0) {
                    const aggregateRef = ref(
                      database,
                      `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/certified/totals`
                    );
                    await set(aggregateRef, null);
                    // Clear all keyTotals when no rows remain
                    await saveKeyTotals([], districtCode, fiscalYear, functionCode, buildingCode);
                  } else {
                    // Recalculate totals
                    aggregated = updatedRows.reduce(
                      (acc, row) => {
                        acc.fte += row.fte || 0;
                        acc.salary += row.salary || 0;
                        acc.extendedContract += row.extendedContract || 0;
                        acc.careerLadder += row.careerLadder || 0;
                        acc.totalCost += row.totalCost || 0;
                        acc.retirement += (row.salary + row.extendedContract + row.careerLadder) * certifiedRetirement;
                        acc.medicare += (row.salary + row.extendedContract + row.careerLadder) * medicareRate;
                        acc.benefits +=
                          ppoMonthly * 12 * row.fte * (row.health || 0) +
                          lifeMonthly * 12 * row.fte * (row.life || 0);
                        return acc;
                      },
                      {
                        fte: 0,
                        salary: 0,
                        extendedContract: 0,
                        careerLadder: 0,
                        totalCost: 0,
                        retirement: 0,
                        medicare: 0,
                        benefits: 0,
                      }
                    );

                    const aggregateRef = ref(
                      database,
                      `staff/${districtCode}/${fiscalYear}/${functionCode}/${buildingCode}/certified/totals`
                    );
                    await set(aggregateRef, {
                      ...aggregated,
                      salaryWithExtendedContract: aggregated.salary + aggregated.extendedContract + aggregated.careerLadder,
                    });
                  }

                  // 3. Save key totals
                  await saveKeyTotals(updatedRows, districtCode, fiscalYear, functionCode, buildingCode);

                  // Update local state
                  setRows(updatedRows);
                }}
              >
                Delete
              </button>
              <button
                  onClick={() => openDetailsModal(row)}
                  className="details-btn"
                >
                  Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan="2">Totals</td>
          <td>{Rows.reduce((sum, row) => sum + (row.fte || 0), 0).toFixed(2)}</td>
          <td>{Rows.reduce((sum, row) => sum + (row.life || 0), 0)}</td>
          <td>{Rows.reduce((sum, row) => sum + (row.health || 0), 0)}</td>
          <td></td>
          <td></td>
          <td>{Rows.reduce((sum, row) => sum + (row.yearsInEducation || 0), 0)}</td>
          <td>{formatDollars(Rows.reduce((sum, row) => sum + (row.salary || 0), 0))}</td>
          <td>
            {formatDollars(
              Rows.reduce((sum, row) => sum + (row.extendedContract || 0), 0)
            )}
          </td>
          <td>
            {formatDollars(
              Rows.reduce((sum, row) => sum + (row.careerLadder || 0), 0)
            )}
          </td>
          <td>
            {formatDollars(
              Rows.reduce((sum, row) => sum + (row.totalCost || 0), 0)
            )}
          </td>
          <td></td>
        </tr>
      </tfoot>
      </table>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h4>Edit Object Code & Fund</h4>
            <label>
              Project:
              <input
                type="text"
                value={selectedRow?.project || "00000"}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    project: e.target.value,
                  }))
                }
              />
            </label>
            <label>
              Source:
              <input
                type="number"
                value={selectedRow?.source || 3}
                onChange={(e) =>
                  setSelectedRow((prev) => ({
                    ...prev,
                    source: parseInt(e.target.value, 10),
                  }))
                }
              />
            </label>
            <label>
              Object Code For Salary:
              <select
                value={objectCode}
                onChange={(e) => setObjectCode(e.target.value)}
              >
                {Object.entries(OBJECT_CODES).map(([code, label]) => (
                  <option key={code} value={code}>
                    {code} - {label}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Fund For Total Compensation:
              <select
                value={fund}
                onChange={(e) => setFund(e.target.value)}
              >
                <option value="GENERAL_FUND">GENERAL_FUND</option>
                <option value="SPECIAL_REVENUE">SPECIAL_REVENUE</option>
                <option value="CAPITAL_FUND">CAPITAL_FUND</option>
                <option value="DEBT_SERVICE_FUND">DEBT_SERVICE_FUND</option>
              </select>
            </label>
            <div className="modal-actions">
              <button onClick={saveDetails}>Save</button>
              <button onClick={() => setModalOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      <button onClick={addRow} className ="add-row-btn" >Add Certified Role</button>
      
    </div>
  );
};

export default React.memo(CertifiedStaffManagementTable);