import React, { useState, useEffect } from "react";
import "../styles/BudgetSummary.css"; // For custom styling
import {formatDollars} from '../utils';
import { FaCalculator } from "react-icons/fa"; // Import calculator icon

const BudgetSummary = ({currentBudget, currentYear}) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility
  const [inputValues, setInputValues] = useState({ WADA: "", SAT: "", transferPercent: "", transferAmount: ""}); // Form state
  let fiscalYearString = `${currentYear}-${parseInt(currentYear)+1}`
  let initialDataArray = [{
                            headers: [" ", "Total", "Residential", "Commercial", "Personal", "Agriculture"],
                            rows: [
                              {
                                label: "Estimated Assessed Valuation",
                                values: ["$67,511,737", "$31,310,345", "$7,720,496", "$21,844,419", "$6,636,477"],
                              },
                            ],
                          },
                          {
                            headers: [" ", "Total", "General", "Special", "Debt", "Capital"],
                            rows: [
                              {
                                label: "Tax Levy",
                                values: ["$4.9962", "$4.0000", "$0.00", "$0.7762", "$0.22"],
                              },
                            ],
                          },
                          {
                            headers: [" ", "Total", "Operations", "Teacher", "Debt", "Capital"],
                            rows: [
                              {
                                label: "Balance June 30 2025",
                                values: ["$6,190,736", "$4,541,600", "$0", "$1,245,498", "$403,638"],
                              },
                              {
                                label: "Estimated Revenue 2025-2026",
                                values: [`${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].TOTAL_ALL_FUNDS)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].GENERAL_FUND)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].SPECIAL_REVENUE)}`,
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].DEBT_SERVICE)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].CAPITAL_PROJECTS)}`],
                              },
                              {
                                label: "Estimated Receipts and Balances 2025-2026",
                                values: [`${formatDollars(6190736+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].TOTAL_ALL_FUNDS)}`, 
                                          `${formatDollars(4541600+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].GENERAL_FUND)}`, 
                                          `${formatDollars(0+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].SPECIAL_REVENUE)}`,
                                          `${formatDollars(1245498+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].DEBT_SERVICE)}`, 
                                          `${formatDollars(403638+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].CAPITAL_PROJECTS)}`],
                              },
                              {
                                label: "Estimated Expenditures 2025-2026",
                                values: [`${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].TOTAL_ALL_FUNDS)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].GENERAL_FUND)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].SPECIAL_REVENUE)}`,
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].DEBT_SERVICE)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].CAPITAL_PROJECTS)}`],
                              },
                              {
                                label: "Revenue - Expenditures",
                                values: [`${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].TOTAL_ALL_FUNDS - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].TOTAL_ALL_FUNDS)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].GENERAL_FUND - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].GENERAL_FUND)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].SPECIAL_REVENUE - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].SPECIAL_REVENUE)}`,
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].DEBT_SERVICE - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].DEBT_SERVICE)}`, 
                                          `${formatDollars(currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].CAPITAL_PROJECTS - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].CAPITAL_PROJECTS)}`],
                              },
                              {
                                label: "Balance Prior to Transfer",
                                values: [`${formatDollars(6190736+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].TOTAL_ALL_FUNDS - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].TOTAL_ALL_FUNDS)}`, 
                                          `${formatDollars(4541600+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].GENERAL_FUND - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].GENERAL_FUND)}`, 
                                          `${formatDollars(0+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].SPECIAL_REVENUE - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].SPECIAL_REVENUE)}`,
                                          `${formatDollars(1245498+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].DEBT_SERVICE - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].DEBT_SERVICE)}`, 
                                          `${formatDollars(403638+currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='revenue'))[0].CAPITAL_PROJECTS - currentBudget.filter(item=>(item.YEAR===currentYear)&(item.LABEL==='expense'))[0].CAPITAL_PROJECTS)}`],
                              },
                              {
                                label: "Zero Teach. Fund Balance",
                                values: ['', 
                                        '', 
                                        '',
                                        '', 
                                        ''],
                              },
                              {
                                label: "Transportation Transfer",
                                values: ['', 
                                        '', 
                                        '',
                                        '', 
                                        ''],
                              },
                              {
                                label: "Grant Match Transfer",
                                values: ['', 
                                        '', 
                                        '',
                                        '', 
                                        ''],
                              },
                              {
                                label: "Capital Projects Transfer",
                                values: ['', 
                                        '', 
                                        '',
                                        '', 
                                        ''],
                              },
                              {
                                label: "Food Service Transfer",
                                values: ['', 
                                        '', 
                                        '',
                                        '', 
                                        ''],
                              },
                              {
                                label: "Student Activities Transfer",
                                values: ['', 
                                        '', 
                                        '',
                                        '', 
                                        ''],
                              },
                              {
                                label: "SB 287 Transfer",
                                values: ['', 
                                        '', 
                                        '',
                                        '', 
                                        ''],
                              },
                              {
                                label: "Estimated Restricted Money",
                                values: ['$9,907', 
                                        '$9,907', 
                                        '',
                                        '', 
                                        ''],
                              },
                            ],
                          }
                        ]

  const initializeData = () => {
    return initialDataArray
  }

  const [data, setData] = useState(initialDataArray);

  useEffect(() => {
    setData(initializeData());
  }, [currentYear, currentBudget]);

  // Compute the sum for rows 6 to 13 in the third table (index 2)
  const computeFundBalance = () => {
    const section = data[2]; // Third table
    const sums = Array(section.headers.length - 1).fill(0); // Initialize sums array

    for (let i = 5; i <= 12; i++) { // Rows 6 to 13
      const row = section.rows[i];
      row.values.forEach((value, colIndex) => {
        if (value && !isNaN(parseFloat(value.replace(/[$,]/g, "")))) {
          sums[colIndex] += parseFloat(value.replace(/[$,]/g, ""));
        }
      });
    }

    return sums.map((sum) => formatDollars(sum));
  };

  const computedFundBalanceRow = {
    label: "Estimated Fund Balance 2026",
    values: computeFundBalance(),
  };

  const computeUnrestMoney = () => {
    const section = data[2]; // Third table
    const sums = Array(section.headers.length - 1).fill(0); // Initialize sums array
    
    const row = section.rows[13];

    row.values.forEach((value, colIndex) => {
      if (value && !isNaN(parseFloat(value.replace(/[$,]/g, "")))) {
        sums[colIndex] += parseFloat(computedFundBalanceRow.values[colIndex].replace(/[$,]/g, ""))
        sums[colIndex] -= parseFloat(value.replace(/[$,]/g, ""));
      }
    });

    return sums.map((sum) => formatDollars(sum));
  };

  const computedUnrestMoneyRow = {
    label: "Estimated Unrest. Money",
    values: computeUnrestMoney(),
  };

  const computeFBPercent = () => {
    const num = parseFloat(computedUnrestMoneyRow.values[1].replace(/[$,]/g, ""));
    const denom = ( parseFloat(data[2].rows[3].values[1].replace(/[$,]/g, ""))+parseFloat(data[2].rows[3].values[2].replace(/[$,]/g, "")));
    return ['',`${(100*num/denom).toFixed(2)}`,'','',''];
  };

  const computedFBPercentRow = {
    label: "% Unrest. Operat",
    values: computeFBPercent(),
  };

   // Handle input change in the form
   const handleInputChange = (field, value) => {
    if (field === "transferPercent" && (value === "" || (Number(value) <= 7 && Number(value) >= 0))) {
      setInputValues((prev) => ({ ...prev, [field]: value }));
    } else if (field !== "transferPercent") {
      setInputValues((prev) => ({ ...prev, [field]: value }));
    }
  };

  const handleModalSubmit = () => {
    const transferAmount = inputValues.SAT*inputValues.WADA*inputValues.transferPercent/100;
    setInputValues((prev) => ({ ... prev, transferAmount: transferAmount}))
    // Update the data variable
    setData((prevData) => {
      const updatedData = [...prevData];
      const section = updatedData[2]; // Third section
      const sb287RowIndex = section.rows.findIndex((row) => row.label === "SB 287 Transfer");

      if (sb287RowIndex !== -1) {
        // Update the 'values' array for the row
        const updatedRow = { ...section.rows[sb287RowIndex] };
        updatedRow.values = updatedRow.values.map((value, index) => 
          index === 1 ? formatDollars(-transferAmount) : value // negative value to column 2
        );
        updatedRow.values = updatedRow.values.map((value, index) => 
          index === 4 ? formatDollars(transferAmount) : value // positive value to column 5
        );
        section.rows[sb287RowIndex] = updatedRow;
      }

      return updatedData;
    });
    setIsModalOpen(false); // Close modal after submission
  };

  // Handle updates to cell values
  const handleCellChange = (sectionIndex, rowIndex, colIndex, value) => {
    const updatedData = data.map((section, secIndex) => {
      if (secIndex === sectionIndex) {
        const updatedRows = section.rows.map((row, rowIdx) => {
          if (rowIdx === rowIndex) {
            const updatedValues = [...row.values];
            updatedValues[colIndex] = value;
            return { ...row, values: updatedValues };
          }
          return row;
        });
        return { ...section, rows: updatedRows };
      }
      return section;
    });
    setData(updatedData);
  };

  return (
    <div className="budget-summary-container">
      <h2>Budget Summary {fiscalYearString}</h2>
      {data.map((section, sectionIndex) => (
        <table key={sectionIndex} className="budget-summary-table">
          <thead>
            <tr>
              {section.headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {section.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row.label}
                {row.label === "SB 287 Transfer" && (
                    <FaCalculator
                      className="calculator-icon"
                      onClick={() => setIsModalOpen(true)}
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  )}
                </td>
                {row.values.map((value, colIndex) => (
                  <td key={colIndex}>
                    <input
                      type="text"
                      value={value}
                      onChange={(e) =>
                        handleCellChange(sectionIndex, rowIndex, colIndex, e.target.value)
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
            {sectionIndex === 2 && (
              <tr>
                <td>{computedFundBalanceRow.label}</td>
                {computedFundBalanceRow.values.map((value, colIndex) => (
                  <td key={colIndex}>
                    <input type="text" value={value} readOnly />
                  </td>
                ))}
              </tr>
            )}
            {sectionIndex === 2 && (
              <tr>
                <td>{computedUnrestMoneyRow.label}</td>
                {computedUnrestMoneyRow.values.map((value, colIndex) => (
                  <td key={colIndex}>
                    <input type="text" value={value} readOnly />
                  </td>
                ))}
              </tr>
            )}
            {sectionIndex === 2 && (
              <tr>
                <td>{computedFBPercentRow.label}</td>
                {computedFBPercentRow.values.map((value, colIndex) => (
                  <td key={colIndex}>
                    <input type="text" value={value} readOnly />
                  </td>
                ))}
              </tr>
            )}
          </tbody>
        </table>
      ))}
      {/* Modal for input form */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            
            <form>
              <div>
              <h3>SB 287 Transfer Calculator</h3>
                <label>WADA:</label>
                <input
                  type="number"
                  value={inputValues.WADA}
                  onChange={(e) => handleInputChange("WADA", e.target.value)}
                />
              </div>
              <div>
                <label>SAT:</label>
                <input
                  type="number"
                  value={inputValues.SAT}
                  onChange={(e) => handleInputChange("SAT", e.target.value)}
                />
              </div>
              <div>
                <label>Transfer Percent:</label>
                <input
                  type="number"
                  value={inputValues.transferPercent}
                  onChange={(e) => handleInputChange("transferPercent", e.target.value)}
                  max="7"
                />
              </div>
              <button type="button" onClick={handleModalSubmit}>
                Submit
              </button>
              <button type="button" onClick={() => setIsModalOpen(false)}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default BudgetSummary;
