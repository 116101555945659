import React, { useState, useEffect } from 'react';
import GeneralDrilldownTable from './GeneralDrilldownTable';
import '../styles/BudgetBuilder.css';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Title } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import BudgetSummary from './BudgetSummary';
import {assignLevels, getUniqueValues, getFirebaseData} from '../utils'
import { database } from '../firebaseConfig';
import { ref, set} from "firebase/database";

ChartJS.register(CategoryScale, LinearScale, LineElement, PointElement, Title,annotationPlugin);

const BudgetBuilder = ({ selectedDistrictInfo, drilldownData, currentBudget, deseCodeMap }) => {

    const availableFiscalYears = getUniqueValues(currentBudget, "YEAR");

    const [expenseData, setExpenseData] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const [surpDefData, setSurpDefData] = useState([]);
    const [isGraphVisible, setIsGraphVisible] = useState(true);
    const [fiscalYear, setFiscalYear] = useState(String(Math.max(Math.max(...availableFiscalYears.map(Number)))));
    const [availableVersions, setAvailableVersions] = useState(getUniqueValues(currentBudget.filter(item=>item.YEAR===fiscalYear), "version"));
    const [version, setVersion] = useState(availableVersions[availableVersions.length-1]);
    const [boardApproved, setBoardApproved] = useState("No");
    const [newVersionCreate, setNewVersionCreate] = useState(false);
    const [newVersionName, setNewVersionName] = useState("");
    const [newVersionBase, setNewVersionBase] = useState("");

    const handleFiscalYearChange = (event) => {
        const updatedAvailableVersions = getUniqueValues(currentBudget.filter(item=>item.YEAR===event.target.value), "version");
        setFiscalYear(event.target.value)
        setAvailableVersions(updatedAvailableVersions)
        setVersion(updatedAvailableVersions[updatedAvailableVersions.length-1])
    }

    const handleVersionChange = (event) => {
        if (event.target.value==='Create New'){
            console.log('creating new version')
            setNewVersionCreate(true);
        } else {
            setVersion(event.target.value)
        }
    }

    const handleVersionNameChange = (e) => {
        setNewVersionName(e.target.value);
        console.log('version name change')
    }

    const handleAssignVersionBase = (e) => {
        setNewVersionBase(e.target.value)
    }

    const saveNewVersion = async () => {
        const example_row = currentBudget[0];
        const baseExpenseDataRef = ref(
            database,
            `budget/${example_row['ID']}/${fiscalYear}/expense`
          );
        const baseExpenseData = await getFirebaseData(baseExpenseDataRef);
        const baseRevenueDataRef = ref(
            database,
            `budget/${example_row['ID']}/${fiscalYear}/revenue`
          );
        const baseRevenueData = await getFirebaseData(baseRevenueDataRef);
        
        Object.keys(baseExpenseData).forEach(acctCode => {
            const dataRef = ref(database, `budget/${example_row['ID']}/${fiscalYear}/expense/${acctCode}/budget/${newVersionName}`)
            set(dataRef, baseExpenseData[acctCode]['budget'][newVersionBase])
        })

        Object.keys(baseRevenueData).forEach(acctCode => {
            const dataRef = ref(database, `budget/${example_row['ID']}/${fiscalYear}/revenue/${acctCode}/budget/${newVersionName}`)
            set(dataRef, baseRevenueData[acctCode]['budget'][newVersionBase])
        })

        setNewVersionCreate(false)
        const nodeData = await getFirebaseData(baseExpenseDataRef);
        setAvailableVersions(Object.keys(nodeData[Object.keys(nodeData)[0]]['budget']))
    }

    const showVersionModal = () => (
        <div className="version-modal">
          <div className="version-modal-content">
            <h4>Create New Version</h4>
            <label>New Version Name:
                <input
                type="text"
                value={newVersionName}
                onChange={handleVersionNameChange}
                placeholder="Enter version name"
                />
            </label>
            <label>Based on version:
                <select id="new-version-select" value={newVersionBase} onChange={handleAssignVersionBase}>
                <option value="" disabled>Select Version</option>
                {availableVersions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
            </label>
            <button onClick={() => {saveNewVersion()}}>Save</button>
            <button onClick={() => {setNewVersionCreate(false)}}>Cancel</button>
          </div>
        </div>
    )

    const handleBoardApprovedChange = (event) => {
        console.log('changing board approved status')
        setBoardApproved(event.target.value)
    }

    function filterNonZeroEntries(data) {
        // Create a map to track whether a LABEL has any non-zero TOTAL
        const labelHasNonZero = {};
      
        // Iterate through the data to check for non-zero TOTAL for each LABEL
        data.forEach((entry) => {
          const { LABEL, TOTAL_ALL_FUNDS } = entry;
          if (!labelHasNonZero[LABEL]) {
            labelHasNonZero[LABEL] = false; // Initialize to false if not already set
          }
          if (TOTAL_ALL_FUNDS !== 0) {
            labelHasNonZero[LABEL] = true; // Mark as true if TOTAL is non-zero
          }
        });
        
        // Filter the original array based on the map
        return data.filter((entry) => labelHasNonZero[entry.LABEL]);
    }

    const filteredPastBudgets = drilldownData.filter(item=>item.DISTRICT_NAME===selectedDistrictInfo['DISTRICT_NAME']);

    currentBudget.map(item=>item['TOTAL_ALL_FUNDS'] = item['GENERAL_FUND']+item['SPECIAL_REVENUE']+item['CAPITAL_PROJECTS']+item['DEBT_SERVICE'])

    // calculate the LEVEL of each LABEL. i.e. PARENT == 'Self' is level 0, its children level 1, etc.
    // this will help us indent the table properly according to the level

    let levelDict = assignLevels(filteredPastBudgets, 'LABEL');
    filteredPastBudgets.forEach(item => item['LEVEL'] = levelDict[item.LABEL]);

    // currentBudget has multiple versions for each fiscal year so we need to filter down to 1 version each.
    // For the current fiscal year we need to use the selected version, for others we'll use the last one in the list.
    let yearVersionMap = {}
    availableFiscalYears.forEach(fYr => {
        if(fYr===fiscalYear){
            yearVersionMap[fYr] = version;
        } else {
            const uniqueVersions = getUniqueValues(currentBudget.filter(item=>item.YEAR===fYr), "version");
            yearVersionMap[fYr] = uniqueVersions[uniqueVersions.length-1]
        }
    })

    let filteredCurrentBudget = []
    availableFiscalYears.forEach(fYr => {
        filteredCurrentBudget = filteredCurrentBudget.concat(currentBudget.filter(item=>item.YEAR === fYr && item.version===yearVersionMap[fYr]))
    })

    let levelDict2 = assignLevels(filteredCurrentBudget, 'LABEL');
    filteredCurrentBudget.forEach(item => item['LEVEL'] = levelDict2[item.LABEL]);

    let currentBudgetYears = getUniqueValues(filteredCurrentBudget, 'YEAR');

    // If ASBR data overlaps with currentBudget data, use currentBudget data
    let concatArray = filteredPastBudgets.filter(item=>!currentBudgetYears.includes(item.YEAR)).concat(filteredCurrentBudget);

    const concatArrayFilt = filterNonZeroEntries(concatArray);

    const hasExpenses = filteredCurrentBudget.filter(item=>item.LABEL==='expense').length>1;

    return (
        <div className='budget-builder-container'>
            {/*
            <div 
                className='toggle-arrow' 
                onClick={() => setIsGraphVisible(!isGraphVisible)} 
            >
                {isGraphVisible ? '▼ Hide Graphs' : '► Show Graphs'}
            </div>
            
            {isGraphVisible && (
                <div className='graph-container'>
                </div>
            )}
            */}
            <div className="year-version">
                <label>Fiscal Year: </label>
                <select id="school-select" value={fiscalYear} onChange={handleFiscalYearChange}>
                    <option value="" disabled>Select Fiscal Year</option>
                    {availableFiscalYears.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
                <label> Version: </label>
                <select id="school-select" value={version} onChange={handleVersionChange}>
                    <option value="" disabled>Select Version</option>
                    {availableVersions.map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                    <option value="+ Create New">Create New</option>
                </select>
                <label> Board Approved: </label>
                <select id="school-select" value={boardApproved} onChange={handleBoardApprovedChange}>
                    <option value="" disabled>Board Approved?</option>
                    {['No', 'Yes'].map((option, index) => (
                        <option key={index} value={option}>{option}</option>
                    ))}
                </select>
            </div>
                {drilldownData ? (
                    <GeneralDrilldownTable
                        data = {concatArrayFilt}
                        tableDataFilters={`item.AGGREGATE && item.YEAR > 2021 && !isNaN(item.TOTAL_ALL_FUNDS)`}
                        tableDataFormats= {{'TOTAL_ALL_FUNDS': 'dollars'}}
                        rowKey = 'LABEL'
                        columnKey = 'YEAR'
                        valueLabel = 'TOTAL_ALL_FUNDS'
                        rowLevelFunctions = {{'graph' : true, 'edit' : true}}
                        fiscalYear = {fiscalYear}
                        version = {version}
                        deseCodeMap={deseCodeMap}
                        title = {`Budget Builder ${fiscalYear}-${parseInt(fiscalYear)+1}`}
                    />
                ) : (
                    <p>Loading data...</p>
                )}
            {hasExpenses && (
            <BudgetSummary currentBudget={filteredCurrentBudget}
                           currentYear={fiscalYear.slice(0,4)}
            />)}
            <div>
                {newVersionCreate && showVersionModal()}
            </div>
        </div>
        
    );
};

export default BudgetBuilder;